import {
  Box,
  Container,
  Button,
  TextField,
  Icon,
  IconButton,
} from "@material-ui/core";
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { GLOBAL_DATA } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Get_API } from "../../../../services/ApiServies";
import { Res } from "../../../../resources";
import { Post_API } from "../../../../services/ApiServies";
import {
  CustomizeToast,
  CustomizeToastError,
} from "../../../../services/CommonLogic";
import { useHistory } from "react-router-dom";
import { isURL } from "../../../../resources/Validator";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid gray",
    width: "100%",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 10,
      border: "1px solid gray",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  firstRow: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "10%",
    marginRight: "10%",
  },
  lastRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  firstContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: 280,
    padding: 40,
    marginTop: 10,
    marginBottom: 10,
  },
  textStyle: {
    color: "#0290A1",
    marginBottom: 15,
    flex: 1,
  },
  textStyle1: {
    color: "#0290A1",
    marginBottom: 16,
    fontWeight: "bold",
    wordBreak: "break-word",
    flex: 1,
  },
  formControl: {
    minWidth: 80,
  },
  container: {
    overflowY: "scroll",
    scrollBehavior: "smooth",
    height: 800,
  },
  MenuItem: {
    fontSize: 11,
    color: "#0290A1",
  },
  logoImg: {
    height: 20,
    width: 20,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  hederContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  headerParent: {
    border: "2px solid black",
    minHeight: 300,
    marginTop: 20,
    paddingBottom: 10,
  },
  headerText: {
    color: "#63244E",
    fontSize: 16,
    fontWeight: "bolder",
    marginLeft: 20,
    marginTop: 20,
  },
  btnStyle: {
    backgroundColor: "#9D88B8",
    color: "#FFFFFF",
    marginRight: 10,
    borderRadius: 10,
    width: 100,
    marginTop: 20,
    padding: "4px 10px",
    fontSize: 10,
    "&:hover": {
      backgroundColor: "#9D88B8",
    },
  },

  btn2Style: {
    backgroundColor: "#63244E",
    color: "#FFFFFF",
    marginRight: 20,
    borderRadius: 10,
    padding: "4px 10px",
    fontSize: 10,
    width: 100,
    marginTop: 20,
    "&:hover": {
      backgroundColor: "#63244E",
    },
  },
  underLine: {
    backgroundColor: "#63244E",
    height: 3,
    marginBottom: 10,
  },
  txt: {
    color: "#0290A1",
    marginTop: 8,
    marginBottom: 8,
  },
  txt1: {
    color: "#0290A1",
    marginTop: 8,
    marginBottom: 8,
    flex: 2,
  },
  synoContainer: {
    display: "flex",
    flexDirection: "row",
  },
  str1: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "5%",
    marginRight: "5%",
  },
  divider: {
    color: "#0290A1",
    marginTop: 8,
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 5,
  },
  item: {
    borderRadius: 15,
    color: "#63244E",
    textAlign: "center",
    marginBottom: 10,
    marginRight: 8,
    padding: 3,
    paddingRight: 0,
    // minWidth: 80,
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  itemcont: {
    display: "flex",
    flexWrap: "wrap",
  },
  boxsame: {
    paddingTop: "20px",
    marginLeft: "5%",
    marginRight: "5%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0%",
      marginRight: "0%",
    },
  },
  add: {
    width: 22,
    height: 35,
  },

  imageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },

  txt: {
    color: "#63244E",
    marginTop: 8,
    marginBottom: 8,
  },
  txt1: {
    color: "#63244E",
    marginTop: 8,
    marginBottom: 8,
    flex: 2,
  },

  divider: {
    color: "#0290A1",
    marginTop: 8,
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 5,
  },

  newMsgTxt: {
    color: "#63244E",
  },

  cmnBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: "60%",
    marginBottom: 30,
  },
}));

export default function AddKeywordScreen(props) {
  const classes = useStyles();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const [age, setAge] = React.useState("");
  const [syno, setSyno] = React.useState("");
  const [key, setKey] = React.useState("");
  const [categories, setCategories] = React.useState();
  const [message, setMessage] = React.useState("");
  const [url, seturl] = React.useState("");
  const [userType, setUserType] = React.useState("");
  const [id, setId] = React.useState();
  const [msgObj, setMsgObj] = React.useState([]);
  const [msgEditFlag, setMsgEditFlag] = React.useState(false);
  const [wordCount, setWordCount] = React.useState(0);
  const synoArray = [];

  React.useEffect(() => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        setCategories(response?.data?.objects);
      } else {
        console.log("Api called failed");
      }
    };
    Get_API(Res.apiEndPoints.categories, null, getModuleSuccess, true);
  }, []);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChange1 = (a) => {
    let value = a.target.value;
    value = value.replace(/[^A-Za-z\s]/gi, "");
    setKey(value);
  };
  const handleChange2 = (a) => {
    let value = a.target.value;
    value = value.replace(/[^A-Za-z\s]/gi, "");
    setSyno(value.toLowerCase());
  };

  const handleChange3 = (b) => {
    let wordLength = b.target.value
      ?.split(new RegExp("\\s+"))
      ?.filter((word) => word != "").length;

    if (wordLength <= 36) {
      setWordCount(wordLength);
      setMessage(b.target.value);
    } else if (wordLength > 36) {
      b.target.value = message;
      CustomizeToastError("You can input only 36 words");
    } else {
      CustomizeToastError("You can input only 36 words");
    }
  };

  const handleChange4 = (b) => {
    seturl(b.target.value);
  };

  const handleChangeUserType = (event) => {
    setUserType(event.target.value);
  };

  React.useEffect(() => {
    setMsgEditFlag(false);
    // Array.isArray(global_data.synoArr) && synoArray?.push(...global_data.synoArr);
    dispatch({
      type: GLOBAL_DATA,
      payload: {
        synoArr: synoArray,
      },
    });
  }, []);

  const saveClick = () => {
    if (global_data && syno.trim() !== "") {
      synoArray.push(...global_data.synoArr, syno);
      setSyno("");
      dispatch({
        type: GLOBAL_DATA,
        payload: {
          synoArr: synoArray,
        },
      });
    }
  };

  const cancelBtnClick = () => {
    history.push("/recommendation");
  };

  const deleteItem = (i) => {
    const Arr = global_data?.synoArr?.filter((syno, index) => index !== i);

    dispatch({
      type: GLOBAL_DATA,
      payload: {
        synoArr: Arr,
      },
    });
  };

  const saveBtnClick = () => {
    if (key.trim() !== "") {
      const addNewKey = (response) => {
        if (response?.data?.success) {
          setMsgEditFlag(true);
          setId(response?.data?.object?.id);
          CustomizeToast(response.data.message);
          dispatch({
            type: GLOBAL_DATA,
            payload: {
              synoArr: response.data.object.synonyms,
            },
          });
          props.reloadRecommendations();
        } else {
          console.log("Api called failed");
          CustomizeToastError(response.data.message);
        }
      };

      if (age !== "" && age !== null) {
        var data = {
          categoryId: age,
          keyword: key,
          synonyms: global_data?.synoArr,
        };
        if (id !== "") {
          data = { ...data, recommendationId: id };
        }
        Post_API(Res.apiEndPoints.addKey, data, addNewKey, true);
      } else {
        CustomizeToastError("Please select category");
      }
    } else {
      CustomizeToastError("Keyword required");
    }
  };

  const saveMsgClick = () => {
    if (isURL(url) && message !== "" && url !== "" && userType !== "") {
      if (id !== "") {
        msgObj.push({
          message: message,
          recommendationId: id,
          url: url,
          userType: userType,
        });
        setMessage("");
        seturl("");
        setWordCount("0");
      }
      const addMsgKey = (response) => {
        if (response?.data?.success) {
          CustomizeToast(response.data.message);
          // history.push("/recommendation");
          props.reloadRecommendations();
        } else {
          console.log("Api called failed");
          CustomizeToastError(response.data.message);
        }
      };
      if (userType !== "") {
        Post_API(
          Res.apiEndPoints.addMessage,
          {
            message: message,
            recommendationId: id,
            url: url,
            userType: userType,
          },
          addMsgKey,
          true
        );
      } else {
        CustomizeToastError("Please select user type");
      }
    } else {
      CustomizeToastError("Message , valid url and user type are required");
    }
  };

  return (
    <Container style={{ padding: 20 }}>
      <Box sx={{ border: "2px solid black" }} className={classes.container}>
        <Container>
          <div className={classes.headerParent}>
            <div className={classes.hederContainer}>
              <Box className={classes.headerText}>Recommendation Details</Box>
              <Box>
                <Button
                  size="small"
                  className={classes.btnStyle}
                  onClick={() => cancelBtnClick()}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  className={classes.btn2Style}
                  onClick={() => saveBtnClick()}
                >
                  Save
                </Button>
              </Box>
            </div>
            <Container>
              <div className={classes.underLine} />
            </Container>
            <Container>
              <Box>
                <Box>
                  <Box className={classes.str1}>
                    <Box style={{ flex: 1 }}>
                      <Box className={classes.txt}>Category : </Box>
                      <Box>
                        <FormControl style={{ width: "80%" }}>
                          <NativeSelect
                            id="demo-customized-select-native"
                            value={age}
                            onChange={handleChange}
                            input={<BootstrapInput />}
                            style={{
                              color: "#63244E",
                              fontWeight: "bold",
                              fontSize: 15,
                            }}
                            // disabled={edit}
                          >
                            <option style={{ textAlign: "center" }} value="">
                              Select Category
                            </option>
                            {Array.isArray(categories) &&
                              categories.map((item, i) => {
                                return (
                                  <option
                                    key={i}
                                    style={{ textAlign: "center" }}
                                    value={item.categoryId}
                                  >
                                    {item?.title}
                                  </option>
                                );
                              })}
                          </NativeSelect>
                        </FormControl>
                      </Box>
                    </Box>
                    <Box style={{ flex: 1 }}>
                      <Box className={classes.txt}>Keyword* :</Box>
                      <Box>
                        <BootstrapInput
                          id="demo-customized-textbox"
                          placeholder="Add a Keyword"
                          onChange={handleChange1}
                          value={key}
                          style={{ width: "80%" }}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Box className={classes.boxsame}>
                    <Box className={classes.txt1}>Add a Synonym :</Box>
                    <Box className={classes.synoContainer}>
                      <Box>
                        <BootstrapInput
                          id="demo-customized-textbox"
                          placeholder="Add Synonyms"
                          onChange={handleChange2}
                          style={{ width: "280px" }}
                          value={syno}
                        />
                      </Box>
                      <Box>
                        <IconButton
                          style={{ padding: 8, marginRight: 10 }}
                          onClick={() => {
                            saveClick();
                          }}
                        >
                          <Icon
                            color="primary"
                            style={{ fontSize: 24, fontWeight: 700 }}
                          >
                            add_circle
                          </Icon>
                        </IconButton>
                      </Box>
                      <Box className={classes.itemcont}>
                        {global_data?.synoArr?.map((syno, i) => {
                          return (
                            <Box
                              key={i}
                              sx={{ border: "1px solid #63244E" }}
                              className={classes.item}
                            >
                              <Box
                                style={{
                                  textAlign: "center",
                                  padding: 5,
                                  textTransform: "capitalize",
                                }}
                              >
                                {syno}
                              </Box>
                              <IconButton
                                style={{ padding: 5, fontSize: 12 }}
                                onClick={() => deleteItem(i)}
                                key={i}
                              >
                                <Icon
                                  style={{
                                    color: "#63244E",
                                    fontSize: 18,
                                    fontWeight: 700,
                                  }}
                                >
                                  close_icon
                                </Icon>
                              </IconButton>
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Container>
          </div>
        </Container>
        <Container>
          {msgEditFlag && (
            <div className={classes.headerParent}>
              <Container>
                <Box className={classes.headerParent}>
                  <Box className={classes.hederContainer}>
                    <Box className={classes.headerText}>Add New Message</Box>
                    <Box>
                      <Button
                        size="small"
                        className={classes.btnStyle}
                        onClick={() => cancelBtnClick()}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="small"
                        className={classes.btn2Style}
                        onClick={() => {
                          saveMsgClick();
                        }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                  <Container>
                    <Box className={classes.underLine} />
                  </Container>
                  <Box style={{ marginLeft: "15%", marginRight: "15%" }}>
                    <Box className={classes.cmnBox}>
                      <Box className={classes.newMsgTxt}>Message* :</Box>
                      {/* <BootstrapInput id="demo-customized-textbox" onChange={handleChange3} style={{ width: '50%' }} /> */}
                      <Box
                        variant="outlined"
                        component="form"
                        sx={{
                          "& .MuiTextField-root": { m: 1, width: "38ch" },
                        }}
                        noValidate
                        autoComplete="on"
                      >
                        <TextField
                          id="outlined-multiline-static"
                          variant="outlined"
                          multiline
                          onChange={handleChange3}
                          rows={3}
                          value={message}
                          inputProps={{ style: { fontSize: 16 } }}
                          required
                        />
                        <Box
                          style={{
                            paddingTop: "5px",
                            fontSize: 13,
                            color: wordCount === 36 ? "#63244E" : "black",
                          }}
                        >
                          {`${wordCount} of 36 words`}
                        </Box>
                      </Box>
                    </Box>

                    <Box className={classes.cmnBox}>
                      <Box className={classes.newMsgTxt}>Message type :</Box>
                      <FormControl>
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={userType}
                          onChange={handleChangeUserType}
                          input={<BootstrapInput />}
                          style={{
                            width: "100%",
                            color: "#63244E",
                            fontWeight: "bold",
                          }}
                          // disabled={edit}
                        >
                          <option aria-label="None" value={""}>
                            Select
                          </option>
                          <option value={"Parent"}>Parent Messages</option>
                          <option value={"Teacher"}>Teacher Messages</option>
                          {/* <option value={'Carer'}>Carer Messages</option> */}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                    <Box className={classes.cmnBox}>
                      <Box className={classes.newMsgTxt}>URL* :</Box>
                      <BootstrapInput
                        id="demo-customized-textbox"
                        onChange={handleChange4}
                        style={{ width: "50%" }}
                        value={url}
                        required
                      />
                    </Box>

                    {/* <Box className={classes.cmnBox}> */}
                    {/* <Box className={classes.newMsgTxt}>Message type 22 :</Box> */}
                    {/* <FormControl>
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={userType}
                          onChange={handleChangeUserType}
                          input={<BootstrapInput />}
                          style={{
                            width: "100%",
                            color: "#63244E",
                            fontWeight: 400,
                            fontSize: 14,
                          }}
                          // disabled={edit}
                        >
                          <option aria-label="None" value={""}>
                            Select
                          </option>
                          <option value={"Parent"}>Parent Messages</option>
                        </NativeSelect>
                      </FormControl> */}
                    {/* </Box> */}
                  </Box>
                </Box>
              </Container>
            </div>
          )}
          {Array.isArray(msgObj) && msgObj?.length !== 0
            ? msgObj.map((item, i) => {
                return (
                  <Box
                    sx={{ border: "2px solid black" }}
                    className={classes.firstContainer}
                    key={i}
                  >
                    <Box className={classes.boxOne}>
                      <Box style={{ fontWeight: "bold", color: "#0290A1" }}>
                        {`${item.userType}:`}
                      </Box>
                      <div className={classes.underLine} />
                    </Box>
                    <Box className={classes.firstRow}>
                      <Box className={classes.textStyle}>Message :</Box>
                      <Box className={classes.textStyle1}>{item.message}</Box>
                    </Box>
                    <Box className={classes.firstRow}>
                      <Box className={classes.textStyle}>URL : </Box>
                      <Box className={classes.textStyle1}>{item.url}</Box>
                    </Box>
                  </Box>
                );
              })
            : null}
        </Container>
      </Box>
    </Container>
  );
}
