import {
  Box,
  IconButton,
  Icon,
  Button,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { Res } from "../../../../resources";
import AddMessage from "./AddMessage";
import { useDispatch, useSelector } from "react-redux";
import { GLOBAL_DATA } from "../../../../store/actions";
import { Get_API } from "../../../../services/ApiServies";
import EditMessage from "./EditMessage";
import EditMessageMain from "./EditMessageMain";
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid black",
    fontSize: 10,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  firstRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  lastRow: {
    display: "flex",
    flexDirection: "row",
  },
  firstContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: 180,
    padding: 40,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 25,
    marginRight: 25,
    border: "2px solid black",
  },
  textStyle: {
    color: "#63244E",
    marginBottom: 10,
    fontSize: 16,
    fontWeight: "bold",
    flex: 1,
  },
  textStyle1: {
    color: "#63244E",
    marginBottom: 10,
    fontSize: 12,
    fontWeight: "bold",
    width: 600,
    flex: 3,
    wordBreak: "break-word",

    [theme.breakpoints.up("lg")]: {
      marginRight: "10%",
    },
  },
  textStyle2: {
    color: "#63244E",
    marginBottom: 10,
    fontSize: 12,
    width: 600,
    flex: 3,
    [theme.breakpoints.up("lg")]: {
      marginRight: "10%",
    },
  },
  formControl: {
    minWidth: 80,
  },
  container: {
    overflow: "scroll",
  },
  MenuItem: {
    fontSize: 11,
    color: "#63244E",
  },
  logoImg: {
    height: 17,
    width: 17,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  boxOne: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  boxTwo: {
    fontSize: 15,
    color: "#63244E",
    marginTop: 8,
    fontWeight: "bolder",
  },
  btnOne: {
    backgroundColor: "#63244E",
    color: "white",
    margin: 10,
    borderRadius: 10,
    fontSize: 10,
    padding: "4px 10px",
    "&:hover": {
      backgroundColor: "#63244E",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function ParentMsg(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const global_data = useSelector((state) => state.commonReducer);
  const [edit, setEdit] = React.useState(false);
  const [recommendation] = React.useState(global_data.recommendationsById);
  const [selectForEdit, setSelectForEdit] = React.useState({});
  const [data, setData] = React.useState(
    Array.isArray(global_data.recommendationsById?.suggestions) &&
      global_data.recommendationsById?.suggestions
  );
  const [parentMessages, setParentMessages] = useState(
    Array.isArray(global_data?.recommendationsById?.suggestions) &&
      data?.filter((item) => item.userType === "Parent")
  );
  const [teacherMessages, setTeacherMessages] = useState(
    Array.isArray(global_data?.recommendationsById?.suggestions) &&
      data?.filter((item) => item.userType === "Teacher")
  );
  const [firstRender, setFirstRender] = React.useState(true);
  const [selectRowIndex, setSelectRowIndex] = React.useState();
  const [msgEdit, setMsgEdit] = React.useState(false);
  const [getIndex, setGetIndex] = React.useState("");
  const [userType, setUserType] = React.useState(0);

  const handleChange = (event, newUserType) => {
    setMsgEdit(false);
    setGetIndex("");
    setSelectForEdit({});
    setUserType(newUserType);
  };

  React.useEffect(() => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        setData(response?.data?.object?.suggestions);
        dispatch({
          type: GLOBAL_DATA,
          payload: {
            recommendationsById: response?.data?.object,
          },
        });
        setFirstRender(false);
      } else {
        console.log("Api called failed");
      }
    };
    if (global_data.selectedRow?.id && firstRender) {
      Get_API(
        Res.apiEndPoints.recommendationsById +
          global_data?.selectedRow?.recommendationId,
        null,
        getModuleSuccess,
        true
      );
    }
  }, []);
  React.useEffect(() => {
    setData(global_data.recommendationsById.suggestions);
    setParentMessages(
      Array.isArray(global_data?.recommendationsById?.suggestions) &&
        global_data.recommendationsById.suggestions?.filter(
          (item) => item.userType === "Parent"
        )
    );
    setTeacherMessages(
      Array.isArray(global_data?.recommendationsById?.suggestions) &&
        global_data.recommendationsById.suggestions?.filter(
          (item) => item.userType === "Teacher"
        )
    );
  }, [global_data.recommendationsById.suggestions]);

  React.useEffect(() => {
    if (!firstRender) {
      const getModuleSuccess = (response) => {
        if (response?.data?.success) {
          setData(response?.data?.object?.suggestions);
          dispatch({
            type: GLOBAL_DATA,
            payload: {
              recommendationsById: response?.data?.object,
            },
          });
          setFirstRender(true);
        } else {
          console.log("Api called failed");
        }
      };
      Get_API(
        Res.apiEndPoints.recommendationsById +
          global_data?.selectedRow?.recommendationId,
        null,
        getModuleSuccess,
        true
      );
    }
  }, [data]);

  const editClick = () => {
    // console.log("Index",indx);
    setMsgEdit(true);
    // setSelectRowIndex(indx);
    // setSelectForEdit(item);
  };
  const editClick1 = () => {
    setEdit(true);
  };
  const cancelBtnClick = () => {
    setEdit(false);
  };
  const loadDatatAfterNewMsg = () => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        setData(response?.data?.object?.suggestions);
        dispatch({
          type: GLOBAL_DATA,
          payload: {
            recommendationsById: response?.data?.object,
          },
        });
        setFirstRender(false);
      } else {
        console.log("Api called failed");
      }
    };
    Get_API(
      Res.apiEndPoints.recommendationsById +
        global_data?.selectedRow?.recommendationId,
      null,
      getModuleSuccess,
      true
    );
  };
  // var parentMessages =
  //   Array.isArray(global_data?.recommendationsById?.suggestions) &&
  //   data?.filter((item) => item.userType === "Parent");
  // var teacherMessages =
  //   Array.isArray(global_data?.recommendationsById?.suggestions) &&
  //   data?.filter((item) => item.userType === "Teacher");

  const newFunction = (i, index, item) => {
    setMsgEdit(i);
    setGetIndex(index);
    setSelectForEdit(item);
  };
  const cancelFunction = () => {
    setMsgEdit(false);
    setGetIndex(null);
  };

  return (
    <Box>
      <Box>
        <Container>
          <Box className={classes.imageContainer}>
            <Box>
              <Button
                size="small"
                className={classes.btnOne}
                onClick={() => editClick1()}
              >
                Add new message
              </Button>
            </Box>
          </Box>
        </Container>
        <>
          {edit ? (
            <AddMessage
              cancelBtnClick={() => {
                cancelBtnClick();
              }}
              loadDatatAfterNewMsg={loadDatatAfterNewMsg}
              data={props.data}
              recId={global_data?.recommendationsById?.recommendationId}
              edit={selectForEdit?.userType && selectForEdit}
            />
          ) : null}
          <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Tabs value={userType} onChange={handleChange} centered>
              <Tab label="Parent Messages" />
              <Tab label="Teacher Messages" />
            </Tabs>
          </Box>
          <Box>
            <TabPanel value={userType} index={0}>
              {Array.isArray(parentMessages) && parentMessages?.length ? (
                parentMessages?.map((item, index) => {
                  return (
                    <Box key={index}>
                      {getIndex !== index && !msgEdit ? (
                        <EditMessageMain
                          item={item}
                          index={index}
                          editClick={(i, index, item) => {
                            newFunction(i, index, item);
                          }}
                        />
                      ) : (
                        <EditMessage
                          cancelBtnClick1={() => {
                            cancelFunction();
                          }}
                          data={props.data}
                          recId={
                            global_data?.recommendationsById?.recommendationId
                          }
                          edit={selectForEdit?.userType && selectForEdit}
                        />
                      )}
                    </Box>
                  );
                })
              ) : (
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className={classes.textStyle2}
                >
                  No messages found
                </Box>
              )}
            </TabPanel>

            <TabPanel value={userType} index={1}>
              {Array.isArray(teacherMessages) && teacherMessages?.length ? (
                teacherMessages?.map((item, index) => {
                  return (
                    <Box key={index}>
                      {getIndex !== index && !msgEdit ? (
                        <EditMessageMain
                          item={item}
                          index={index}
                          editClick={(i, index, item) => {
                            newFunction(i, index, item);
                          }}
                        />
                      ) : (
                        <EditMessage
                          cancelBtnClick1={() => {
                            cancelFunction();
                          }}
                          data={props.data}
                          recId={
                            global_data?.recommendationsById?.recommendationId
                          }
                          edit={selectForEdit?.userType && selectForEdit}
                        />
                      )}
                    </Box>
                  );
                })
              ) : (
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className={classes.textStyle2}
                >
                  No messages found
                </Box>
              )}
            </TabPanel>

            {Array.isArray(global_data.Addmsg) &&
              global_data?.Addmsg?.map((item, index) => {
                return (
                  <Box key={index}>
                    <Box className={classes.firstContainer}>
                      <Box className={classes.imageContainer}>
                        <IconButton>
                          <Icon
                            style={{
                              color: "#63244E",
                              fontSize: 18,
                              fontWeight: 700,
                            }}
                          >
                            close_icon
                          </Icon>
                        </IconButton>
                      </Box>

                      <div className={classes.firstRow}>
                        <div className={classes.textStyle}>Message :</div>
                        <div className={classes.textStyle1}>
                          No messages found
                        </div>
                      </div>

                      <div className={classes.firstRow}>
                        <div className={classes.textStyle}>URL : </div>
                        <div className={classes.textStyle2}>No urls found</div>
                      </div>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </>
      </Box>
    </Box>
  );
}

export default ParentMsg;
