import { apiEndPoints } from './ApiUrls';
import { images } from './Images';
import { variables } from './Variables';
import  theme  from './CustomTheme';

export const Res = {
    apiEndPoints,
    images,
    variables,
    theme
}