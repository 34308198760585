import { Res } from "../resources";
import { CustomizeToastError } from "./CommonLogic";

var validator = require("email-validator");

export function nameValidator(name, type, callback) {
  const nameValue = name ? name.trim() : '';
  let error;
  if (nameValue.length <= 0) {
    error = `Name is required`;
  } else if (isNaN(nameValue[0]) === false) {
    error = "First Name or Last Name cannot start with a number.";
  } else if ([...nameValue].some((char) => "!#$%&'*+-/=?^_`{|}~".includes(char))) {
    error = "First Name or Last Name cannot contain spacial characters";
  }
  if (error) {
    typeof callback === "function"
      ? callback(error)
      : CustomizeToastError(error, Res.variables.toastTimerDelay);
    return false;
  } else {
    return true;
  }
}

export function passwordValidator(password, callback) {
  password = password.trim();
  let error = "";
  if (password.length <= 3) {
    error = "Password should have at least 4 characters";
  }
  if (error) {
    typeof callback === "function"
      ? callback(error)
      : CustomizeToastError(error, Res.variables.toastTimerDelay);
    return false;
  } else {
    return true;
  }
}

export function emailValidator(email, callback) {
  email = email.trim();
  let error;
  if (email.length <= 0) {
    error = "Please enter you email id";
  } else if (!validator.validate(email) || !isEmailSpecificWay(email)) {
    error = "Please enter a valid email id";
  }
  if (error) {
    typeof callback === "function"
      ? callback(error)
      : CustomizeToastError(error, Res.variables.toastTimerDelay);
    return false;
  } else {
    return true;
  }
}



function isEmailSpecificWay(email) {
  let arr = email.split(".");
  let ifValid = true;
  let acc = {};
  arr.forEach((part) => {
    if (part in acc) {
      ifValid = false;
      return;
    }
    acc[part] = 1;
  });
  return ifValid;
}
