import { Box, Typography, Link } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { headers6 } from "../../tableLayoutData/tableData";
import { useLocation } from "react-router-dom";
import AddKeyword from "./AddKeyword";
import { makeStyles } from "@material-ui/core/styles";
import { Res } from "../../../../resources";
import { Get_API, Get_API_With_Token } from "../../../../services/ApiServies";
import AddKeywordScreen from "./AddKeywordScreen";
import TableRecommendation from "./TableRecommendation";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  firstBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  fBtn: {
    // backgroundColor: '#63244E',
    color: "blue",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
    marginTop: 2,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
}));

function Recommendation() {
  const location = useLocation();
  const classes = useStyles();
  const global_data = useSelector((state) => state.commonReducer);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [tab2Data, setTab2Data] = useState([]);

  const AddKey = (i) => {
    setAdd(i);
    setEdit(false);
  };
  const editKey = (i) => {
    setEdit(true);
    setAdd(false);
  };
  useEffect(() => {
    setAdd(false);
    setEdit(false);
  }, [location]);

  // useEffect(() => {
  //   const getModuleSuccess = (response) => {
  //     console.log("SUCCESS", response?.data?.success);
  //     if (response?.data?.success) {
  //       var newData = response?.data?.objects?.map((item) => {
  //         return { ...item, synonyms: item?.synonyms?.join(", ") };
  //       });
  //       console.log("synonyms", newData);
  //       setTab2Data(newData && newData);
  //       console.log("TABLEDATA", tab2Data);
  //     } else {
  //       console.log("Api called failed");
  //     }
  //   };
  //   Get_API(Res.apiEndPoints.recommendationsAll, null, getModuleSuccess, true);
  // }, []);

  useEffect(() => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        var newData = response?.data?.objects?.map((item) => {
          return {
            ...item,
            synonyms: item?.synonyms?.join(", "),
            messages: item?.suggestions
              ? item?.suggestions
                  ?.map?.(
                    (suggestion) =>
                      suggestion.suggestion && suggestion.suggestion
                  )
                  ?.join(" | ")
              : "No Suggestions",
          };
        });
        setTab2Data(newData && newData);
      } else {
        console.log("Api called failed");
      }
    };
    Get_API_With_Token(
      Res.apiEndPoints.recommendationsAll,
      null,
      getModuleSuccess,
      global_data.token
    );
  }, [location.pathname, global_data.token]);

  const reloadRecommendations = () => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        var newData = response?.data?.objects?.map((item) => {
          return {
            ...item,
            synonyms: item?.synonyms?.join(", "),
            messages: item?.suggestions
              ? item?.suggestions
                  ?.map?.((suggestion) =>
                    suggestion.suggestion ? suggestion.suggestion : ""
                  )
                  ?.join(" , ")
              : "No Suggestions",
          };
        });
        setTab2Data(newData && newData);
      } else {
        console.log("Api called failed");
      }
    };
    Get_API_With_Token(
      Res.apiEndPoints.recommendationsAll,
      null,
      getModuleSuccess,
      global_data.token
    );
  };

  return (
    <Box>
      {(!add && !edit && (
        <Box>
          <Box className={classes.firstBox}>
            <Typography style={{ fontSize: 22 }}>
              Journal Recommendation{" "}
            </Typography>
            <Typography style={{ fontSize: 22 }}>
              {" "}
              {`Total Records: ${tab2Data?.length}`}
            </Typography>
            <Link className={classes.fBtn} onClick={() => editKey()}>
              Add Record
            </Link>
          </Box>
          <Box></Box>
          <TableRecommendation
            headers={headers6}
            data={tab2Data}
            func={(i) => {
              AddKey(i);
            }}
            // func1={(i)=>{editKey(i)}}
          />
        </Box>
      )) ||
        (add && !edit && (
          <AddKeyword reloadRecommendations={reloadRecommendations} />
        )) ||
        (edit && !add && (
          <AddKeywordScreen reloadRecommendations={reloadRecommendations} />
        ))}
    </Box>
  );
}

export default Recommendation;
