import CssBaseline from "@material-ui/core/CssBaseline";
import "./index.css";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import Login from "./containers/unAuth/Login";
import { Res } from "./resources";
import { useSelector, useDispatch } from "react-redux";
import Admin from "./containers/auth/Admin";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import { useLocation } from "react-use";
import jwt_decode from "jwt-decode";
import AddKeyword from "./containers/auth/AdminTabs/recommendation/AddKeyword";
import { ToastContainer } from "react-toastify";
import { DELETE_DATA, GLOBAL_DATA } from "./store/actions";
import history from "./resources/history";
import { ContentEditor } from "./containers/auth/ContentEditor";

function App() {
  const global_data = useSelector((state) => state.commonReducer);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = global_data.token;
    if (token) {
      let decodedToken = jwt_decode(token);
      let currentDate = new Date();
      if (decodedToken?.exp * 1000 < currentDate.getTime()) {
        dispatch({ type: DELETE_DATA });
        localStorage?.clear();
        history?.push("/login");
      }
    }
  }, [global_data.token, location?.pathname, dispatch]);

  // const global_data = useSelector((state) => state.commonReducer);

  return (
    <div style={{ backgroundColor: "white", minHeight: "100vh" }}>
      <ToastContainer />
      <ThemeProvider theme={Res.theme}>
        <CssBaseline />
        <BrowserRouter>
          {global_data.token ? (
            <Switch>
              <Route path="/recommendation/addKeyword" component={AddKeyword} />
              <Route path="/content/:contentId" component={ContentEditor} />
              <Route path={`/`}>
                <Admin />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/" component={Login} />
            </Switch>
          )}
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
