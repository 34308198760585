import { Box, Container, Button, IconButton, Icon } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { GLOBAL_DATA } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Get_API, Post_API } from "../../../../services/ApiServies";
import {
  CustomizeToast,
  CustomizeToastError,
} from "../../../../services/CommonLogic";
import { useHistory } from "react-router-dom";
import { Res } from "../../../../resources";
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid gray",
    width: "100%",
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 10,
      border: "1px solid gray",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  firstRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  lastRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  firstContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: 280,
    padding: 40,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 25,
    marginRight: 25,
  },
  textStyle: {
    color: "#63244E",
    marginBottom: 15,
  },
  textStyle1: {
    color: "#63244E",
    marginBottom: 16,
    fontWeight: "bold",
    wordBreak: "break-word",
  },
  formControl: {
    minWidth: 80,
  },
  container: {
    overflowY: "scroll",
    scrollBehavior: "smooth",
    height: 800,
  },
  MenuItem: {
    fontSize: 11,
    color: "#63244E",
  },
  logoImg: {
    height: 20,
    width: 20,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  hederContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  headerParent: {
    border: "2px solid black",
    minHeight: 300,
    marginTop: 20,
    paddingBottom: 10,
  },
  headerText: {
    color: "#63244E",
    fontSize: 16,
    fontWeight: "bolder",
    marginLeft: 20,
    marginTop: 20,
  },
  btnStyle: {
    backgroundColor: "#9D88B8",
    color: "#FFFFFF",
    marginRight: 10,
    borderRadius: 10,
    width: 100,
    padding: "4px 10px",
    fontSize: 10,
    marginTop: 20,
    "&:hover": {
      backgroundColor: "#9D88B8",
    },
  },
  btn2Style: {
    backgroundColor: "#63244E",
    color: "#FFFFFF",
    marginRight: 20,
    borderRadius: 10,
    width: 100,
    marginTop: 20,
    padding: "4px 10px",
    fontSize: 10,
    "&:hover": {
      backgroundColor: "#63244E",
    },
  },
  underLine: {
    backgroundColor: "#63244E",
    height: 3,
    marginBottom: 10,
  },
  txt: {
    color: "#63244E",
    marginTop: 8,
    marginBottom: 8,
  },
  txt1: {
    color: "#63244E",
    marginTop: 8,
    marginBottom: 8,
    flex: 2,
  },
  synoContainer: {
    display: "flex",
    flexDirection: "row",
  },
  str1: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "5%",
    marginRight: "5%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0%",
      marginRight: "0%",
    },
  },
  divider: {
    color: "#63244E",
    marginTop: 8,
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 5,
  },
  item: {
    borderRadius: 15,
    color: "#63244E",
    textAlign: "center",
    marginBottom: 10,
    marginRight: 8,
    padding: 3,
    paddingRight: 0,
    // minWidth: 80,
    fontSize: 12,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  itemcont: {
    display: "flex",
    flexWrap: "wrap",
  },
  boxsame: {
    paddingTop: "20px",
    marginLeft: "5%",
    marginRight: "5%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0%",
      marginRight: "0%",
    },
  },
  add: {
    width: 22,
    height: 35,
  },
}));

export default function EditKeyword(props) {
  const classes = useStyles();
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const dispatch = useDispatch();
  const [age, setAge] = React.useState(props.data.categoryId);
  const [syno, setSyno] = React.useState("");
  const [categories, setCategories] = useState([]);
  const [key, setKey] = useState(props?.data?.keyword);
  const [category, SetCategory] = useState(props.data.category);
  const [concatArray, setConcatArray] = useState([...props?.data?.synonyms]);

  const handleChange = (event) => {
    setAge(event.target.key);
    SetCategory(event.target.value);
  };
  const handleChange1 = (a) => {
    let value = a.target.value;
    value = value.replace(/[^A-Za-z\s]/gi, "");
    setKey(value);
  };
  const handleChange2 = (b) => {
    let value = b.target.value;
    value = value.replace(/[^A-Za-z\s]/gi, "");
    setSyno(value.toLowerCase());
  };

  React.useEffect(() => {
    if (props.data?.synonyms && Array.isArray(props.data.synonyms)) {
      // setConcatArray(synoArray.concat(props?.data?.synonyms));
      dispatch({
        type: GLOBAL_DATA,
        payload: {
          synoArr: concatArray,
        },
      });
    }
  }, []);

  const saveClick = () => {
    syno.trim() !== "" && concatArray?.push(syno);
    setSyno("");
    dispatch({
      type: GLOBAL_DATA,
      payload: {
        synoArr: concatArray,
      },
    });
  };
  const editKeyword = () => {
    if (key.trim() !== "") {
      const addNewKey = (response) => {
        if (response?.data?.success) {
          props.setData({
            categoryId: age,
            category: category,
            keyword: key,
            recommendationId: props?.data?.recommendationId,
            synonyms: response.data.object.synonyms,
          });
          props.saveBtnClick();
          // history.push("/recommendation")
          CustomizeToast(response?.data?.message);
        } else {
          console.log("Api called failed");
          CustomizeToastError(response.data.message);
        }
      };

      Post_API(
        Res.apiEndPoints.addKey,
        {
          categoryId: categories.find((cate) => cate.title === category)
            ?.categoryId,
          keyword: key,
          recommendationId: props?.data?.recommendationId,
          synonyms: concatArray,
        },
        addNewKey,
        true
      );
    } else {
      CustomizeToastError("Keyword is required");
    }
  };

  React.useEffect(() => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        setCategories(response?.data?.objects);
      } else {
        console.log("Api called failed");
      }
    };
    Get_API(Res.apiEndPoints.categories, null, getModuleSuccess, true);
  }, []);

  // const saveBtnClick = () =>{
  //         setSve(true)
  // };

  const deleteItem = (i) => {
    //    const Arr = global_data?.synoArr.splice(id,1);
    const Arr =
      Array.isArray(concatArray) &&
      concatArray?.filter((syno, index) => index !== i);
    setConcatArray(Arr);

    dispatch({
      type: GLOBAL_DATA,
      payload: {
        synoArr: concatArray,
      },
    });
  };

  return (
    <Container>
      <Box>
        <Box className={classes.headerParent}>
          <Box className={classes.hederContainer}>
            <Box className={classes.headerText}>Recommendation Details</Box>
            <Box>
              <Button
                size="small"
                className={classes.btnStyle}
                onClick={props.saveBtnClick}
              >
                Cancel
              </Button>
              <Button
                size="small"
                className={classes.btn2Style}
                onClick={() => editKeyword()}
              >
                Save
              </Button>
            </Box>
          </Box>
          <Container>
            <Box className={classes.underLine} />
          </Container>
          <Container>
            <Box>
              <Box>
                <Box className={classes.str1}>
                  <Box style={{ flex: 1 }}>
                    <Box className={classes.txt}>Category :{category}</Box>
                    <Box>
                      <FormControl style={{ width: "80%" }}>
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={category}
                          onChange={handleChange}
                          input={<BootstrapInput />}
                          style={{
                            width: "100%",
                            color: "#63244E",
                            fontWeight: "bold",
                            fontSize: 15,
                          }}
                          // disabled={edit}
                        >
                          {Array.isArray(categories) &&
                            categories.map((item, id) => {
                              return (
                                <option
                                  value={item?.title}
                                  style={{ textAlign: "center" }}
                                  key={item?.categoryId}
                                >
                                  {item?.title}
                                </option>
                              );
                            })}
                        </NativeSelect>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box style={{ flex: 1 }}>
                    <Box className={classes.txt}>Keyword* :</Box>
                    <Box>
                      <BootstrapInput
                        id="demo-customized-textbox"
                        placeholder="Type to scarch keywords"
                        onChange={handleChange1}
                        value={key}
                        style={{ width: "80%" }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className={classes.boxsame}>
                  <Box className={classes.txt1}>Add a Synonym :</Box>
                  <Box className={classes.synoContainer}>
                    <Box>
                      <BootstrapInput
                        id="demo-customized-textbox"
                        placeholder="Add Synonyms"
                        onChange={handleChange2}
                        style={{ minWidth: "300px" }}
                        value={syno}
                      />
                    </Box>
                    <Box>
                      <IconButton
                        style={{ padding: 8, marginRight: 10 }}
                        onClick={() => {
                          saveClick();
                        }}
                      >
                        <Icon
                          color="primary"
                          style={{ fontSize: 24, fontWeight: 700 }}
                        >
                          add_circle
                        </Icon>
                      </IconButton>
                    </Box>
                    <Box className={classes.itemcont}>
                      {concatArray?.map((syno, i) => {
                        return (
                          <Box
                            key={i}
                            sx={{ border: "1px solid #63244E" }}
                            className={classes.item}
                          >
                            <Box
                              style={{
                                textAlign: "center",
                                padding: 5,
                                textTransform: "capitalize",
                              }}
                            >
                              {syno}
                            </Box>
                            <IconButton
                              style={{ padding: 5, fontSize: 12 }}
                              onClick={() => deleteItem(i)}
                              key={i}
                            >
                              <Icon
                                style={{
                                  color: "#63244E",
                                  fontSize: 18,
                                  fontWeight: 700,
                                }}
                              >
                                close_icon
                              </Icon>
                            </IconButton>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Container>
  );
}
