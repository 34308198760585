import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { Res } from "../resources";

const useStyles = makeStyles((theme) => ({
  container: {
    border: "2px solid",
    borderColor: "#970000",
    minHeight: "100px",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  invalidImg: {
    width: "2.5em",
    height: "2.5em",
    marginBottom: theme.spacing(1),
  },
}));
const AuthValidationError = ({ children }) => {
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      <img
        src={Res.images.inValidImg}
        alt={Res.images.inValidImg}
        className={styles.invalidImg}
      />
      {children}
    </Box>
  );
};

export default AuthValidationError;
