import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Route, Switch, useHistory } from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import { LoginSignIn, ContentPage, WebinarPage } from "./AdminTabs";
import { headers1, rows1 } from "./tableLayoutData/tableData";
import { useLocation } from "react-use";
import JournalActivity from "./AdminTabs/journal/JournalActivity";
import Recommendation from "./AdminTabs/recommendation/Recommendation";
import Journal from "./AdminTabs/journal/Journal";
import ReportsPage from "./AdminTabs/reports/ReportsPage";
// import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  // tableContainer: {
  //   padding: theme.spacing(5),
  //   minHeight: "50vh",
  // },
}));

const AdminContent = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (pathname === "/" || pathname === "") {
      history.push("/recommendation");
    }
  });
  return (
    <TableContainer className={classes.tableContainer}>
      <Switch>
        <Route path={`/signup_login_reports`}>
          <LoginSignIn headers={headers1} rows={rows1} />
        </Route>
        {/* <Route path={`/courses_enrollment`}>
          <ContentPage />
        </Route> */}
        <Route path={`/user_journal_activity`}>
          <JournalActivity />
        </Route>
        <Route path={`/reports`}>
          <ReportsPage />
        </Route>
        <Route path={`/recommendation`}>
          <Recommendation />
        </Route>

        <Route path={`/app_journal`}>
          <Journal />
        </Route>
      </Switch>
    </TableContainer>
  );
};
export default AdminContent;
