export const images = {
  logoImage: "/images/favicon-32x32.png",
  userImage: "/images/user.png",
  appImage: "/images/journalApp.png",
  loginImage: "/images/login.png",
  signupImage: "/images/signup.png",
  whoWeAre: "/images/whoweareimage.svg",
  coachingImg: "/images/coaching.svg",
  inValidImg: "/images/invalid.svg",
  congImg: "/images/congo.png",
  arrImg: "/images/Arrow.png",
  authCourses: "/images/learncourses.png",
  courseBadge: "/images/course_badge.png",
  pen: "/images/pen.png",
  add: "/images/add.png",
  cross: "/images/cross.png",
  familyJournalCms: "/images/family_journal_cms.png",
  teacherJournalCms: "/images/teacher_journal_cms.png",
};
