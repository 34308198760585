import { Box, IconButton, Container, Icon, Link } from "@material-ui/core";
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { Res } from "../../../../resources";
import AddMessage from "./AddMessage";
import { useDispatch, useSelector } from "react-redux";
import { GLOBAL_DATA } from "../../../../store/actions";
import { Get_API } from "../../../../services/ApiServies";
import EditMessage from "./EditMessage";
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid black",
    fontSize: 10,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  firstRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  lastRow: {
    display: "flex",
    flexDirection: "row",
  },
  firstContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "3%",
    paddingTop: "1%",
    paddingBottom: "1%",
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 25,
    marginRight: 25,
    [theme.breakpoints.down("sm")]: {
      margin: 5,
    },
    border: "2px solid black",
  },
  textStyle: {
    color: "#63244E",
    marginBottom: 10,
    fontSize: 16,
    fontWeight: "bold",
    flex: 1,
  },
  textStyle1: {
    color: "#63244E",
    marginBottom: 10,
    fontSize: 12,
    fontWeight: "bold",
    width: 600,
    flex: 3,
    wordBreak: "break-word",

    [theme.breakpoints.up("lg")]: {
      marginRight: "10%",
    },
  },
  textStyle2: {
    color: "#63244E",
    marginBottom: 15,
    fontSize: 12,
    width: 600,
    flex: 3,
    [theme.breakpoints.up("lg")]: {
      marginRight: "10%",
    },
  },
  formControl: {
    minWidth: 80,
  },
  container: {
    overflow: "scroll",
  },
  MenuItem: {
    fontSize: 11,
    color: "#63244E",
  },
  logoImg: {
    height: 17,
    width: 17,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  boxOne: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  boxTwo: {
    fontSize: 15,
    color: "#63244E",
    marginTop: 8,
    fontWeight: "bolder",
  },
  btnOne: {
    backgroundColor: "#63244E",
    color: "white",
    margin: 10,
    borderRadius: 8,
    "&:hover": {
      backgroundColor: "#63244E",
    },
  },
}));

export default function EditMessageMain(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const global_data = useSelector((state) => state.commonReducer);
  const [edit, setEdit] = React.useState(false);
  const [recommendation] = React.useState(global_data.recommendationsById);
  const [selectForEdit, setSelectForEdit] = React.useState({});
  const [data, setData] = React.useState(
    Array.isArray(global_data.recommendationsById?.suggestions) &&
      global_data.recommendationsById?.suggestions
  );
  const [firstRender, setFirstRender] = React.useState(true);
  const [selectRowIndex, setSelectRowIndex] = React.useState();
  const [msgEdit, setMsgEdit] = React.useState(false);

  React.useEffect(() => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        setData(response?.data?.object?.suggestions);
        dispatch({
          type: GLOBAL_DATA,
          payload: {
            recommendationsById: response?.data?.object,
          },
        });
        setFirstRender(false);
      } else {
        console.log("Api called failed");
      }
    };
    if (global_data.selectedRow?.id && firstRender) {
      Get_API(
        Res.apiEndPoints.recommendationsById +
          global_data?.selectedRow?.recommendationId,
        null,
        getModuleSuccess,
        true
      );
    }
  }, []);
  React.useEffect(() => {
    if (!firstRender) {
      const getModuleSuccess = (response) => {
        if (response?.data?.success) {
          setData(response?.data?.object?.suggestions);
          dispatch({
            type: GLOBAL_DATA,
            payload: {
              recommendationsById: response?.data?.object,
            },
          });
          setFirstRender(true);
        } else {
          console.log("Api called failed");
        }
      };
      Get_API(
        Res.apiEndPoints.recommendationsById +
          global_data?.selectedRow?.recommendationId,
        null,
        getModuleSuccess,
        true
      );
    }
  }, [data]);
  // const editClick = () => {
  //     // console.log("Index", indx);
  //     setMsgEdit(true)
  //     // setSelectRowIndex(indx);
  //     // setSelectForEdit(item);
  //     setEdit(true);
  // }
  // const editClick1 = () => {
  //     setEdit(true);
  //     setMsgEdit(false)
  // }
  // const cancelBtnClick = () => {
  //     setSelectRowIndex("")
  //     setEdit(false);
  // }
  return (
    <Box className={classes.firstContainer}>
      <Box className={classes.imageContainer}>
        <IconButton
          style={{ padding: 4 }}
          onClick={() => {
            props.editClick(false, props?.index, props?.item);
          }}
        >
          <Icon color="primary" style={{ fontSize: 19, fontWeight: 500 }}>
            create_icon
          </Icon>
        </IconButton>
      </Box>
      <div className={classes.firstRow}>
        <div className={classes.textStyle}>Message :</div>
        <div className={classes.textStyle1}>{props?.item?.suggestion}</div>
      </div>
      <div className={classes.firstRow}>
        <div className={classes.textStyle}>URL : </div>
        <div className={classes.textStyle2}>
          <a
            target={"_blank"}
            style={{
              textDecoration: "none",
              color: Res.theme.palette.secondary.main,
            }}
            href={props?.item?.url}
          >
            {props?.item?.url}
          </a>
        </div>
      </div>
    </Box>
  );
}
