import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Grid, makeStyles } from "@material-ui/core";
import "../index.css";

const useStyles = makeStyles((theme) => ({
  downloadIcon: {
    color: "#63244E",
    height: 35,
    width: 35,
    borderRadius: 8,
    marginBottom: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableContainer: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.55em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#2E9F92",
      borderRadius: theme.spacing(0.2),
    },
  },
}));

export default function TableCommon({ serialNoDataField, ...props }) {
  const classes = useStyles();
  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Grid item>
          <GetAppIcon
            className={classes.downloadIcon}
            fontSize="small"
            onClick={handleClick}
          />
        </Grid>
      </div>
    );
  };
  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total"
      style={{ marginLeft: 15 }}
    >
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: props?.data?.length,
      },
    ],
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      // dispatch({
      //     type: GLOBAL_DATA,
      //     payload: {
      //         selectedRow: row,
      //     },
      // });
      // CustomizeToast("ghgfhgf")
    }
  };

  const selectRow = {
    mode: "radio",
    clickToSelect: true,
    onSelect: handleOnSelect,
    hideSelectColumn: true,
    bgColor: "#E6E6FA",
  };

  const rowClasses = () => {
    return { borderColor: "black", borderWidth: "2" };
  };

  return (
    <div>
      <ToolkitProvider
        bootstrap4
        keyField="id"
        data={props?.data}
        columns={props?.headers}
        exportCSV={{
          fileName: `${props.tableName}.csv`,
          onlyExportFiltered: true,
          exportAll: false,
          // noAutoBOM: false,
          blobType: "text/csv;charset=ansi",
        }}
      >
        {(props) => (
          <React.Fragment>
            <div style={{ display: "flex", justifyContent: "right" }}>
              <MyExportCSV {...props.csvProps} />
            </div>
            <div
              className={classes.tableContainer}
              style={{
                overflow: "scroll",
                scrollBehavior: "smooth",
                maxHeight: 600,
                overflowX: "hidden",
                scrollbarColor: "skyblue",
                borderBottom: "1px solid black",
                borderLeft: "1px solid #F0F0F0",
                minWidth: "100%",
              }}
            >
              <BootstrapTable
                pagination={paginationFactory(options)}
                filter={filterFactory({
                  afterFilter: (a, b) => {
                    if (serialNoDataField) {
                      a.forEach((val, i) => (val[serialNoDataField] = i + 1));
                    }
                  },
                })}
                {...props.baseProps}
                hover
                striped
                rowClasses={rowClasses}
                noDataIndication="There is no data"
                selectRow={selectRow}

                // caption={<CaptionElement />}
                // expandRow={ expandRow }
              />
            </div>
          </React.Fragment>
        )}
      </ToolkitProvider>
    </div>
  );
}
