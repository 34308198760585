import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import TableCommon from "../../../../components/TableCommon";
import { headers2, } from "../../tableLayoutData/tableData";
import { Res } from "../../../../resources";
import { Get_API } from "../../../../services/ApiServies";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-root": { borderBottom: "none" },
    border: "2px solid #24355C",
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 10,
    padding: 5,
    boxShadow: "0px 0px 1px",
    backgroundColor: "#dee2e6"
  },
  rowLabel: {
    fontWeight: 600,
    color: "#24355C",
    textAlign: "center",
  },
  tableRow: {
    "& .MuiTableCell-head": {
      fontSize: ".80em",
      color: "#24355C",
      fontWeight: 700,
      border: "2px solid #24355C",
    },
  },
  dataCell: {
    borderLeft: "2px solid #24355C",
  },
  bullet: {
    "&::before": {
      content: "",
      width: "5px",
      height: "5px",
      backgroundColor: theme.palette.primary.main,
    },
  },
  headerText: {
    color: "white",
    textAlign: "center",
    fontSize: 20,
    fontWeight: '600'
  },
  boxContainer: {
    backgroundColor: "#63244E",
    marginBottom: 15,
    padding: 10
  },
  subText: { textAlign: 'center', fontSize: 16 },
}));
const ContentPage = () => {
  const classes = useStyles();
  const [tab2Data, setTab2Data] = useState([]);
  const [dataCount, setDataCount] = useState([]);





  useEffect(() => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        setTab2Data(response?.data?.objects);
      } else {
        console.log("Api called failed");
      }
    }
    Get_API(
      Res.apiEndPoints.adminCourseDashboard,
      null,
      getModuleSuccess,
      true
    );

    const getCountSuccess = (response) => {
      if (response?.data) { setDataCount(response?.data); } else { console.log("Api called failed"); }
    }
    Get_API(Res.apiEndPoints.contentCount, null, getCountSuccess, true);

  }, [])
  return (
    <div >

      <Box className={classes.boxContainer}>
        <Typography className={classes.headerText}>Content</Typography>
      </Box>
      <Box className={classes.container}>
        <Box>
          <Typography variant="caption">Total Contents</Typography>
          <Typography className={classes.subText}>{dataCount.totalContents}</Typography>
        </Box>
        <Box>
          <Typography variant="caption">Total Courses</Typography>
          <Typography className={classes.subText}>{dataCount.courses}</Typography>
        </Box>
        <Box>
          <Typography variant="caption">Total Podcasts</Typography>
          <Typography className={classes.subText}>{dataCount.podcasts}</Typography>
        </Box>
        <Box>
          <Typography variant="caption">Total Resources</Typography>
          <Typography className={classes.subText}>{dataCount.resources}</Typography>
        </Box>
      </Box>
      <TableCommon headers={headers2} data={tab2Data} tableName="contentHub" />
    </div>
  );
};

export default ContentPage;
