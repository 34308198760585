import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

export default function AlertDialog({
  open,
  handleClickOpen,
  handleClose,
  title,
  description,
  onYes = () => {},
  onNo = () => {},
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            onYes();
          }}
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            handleClose();
            onNo();
          }}
          autoFocus
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
