import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import TableCommon from "../../../../components/TableCommon";
import { Res } from "../../../../resources";
import { Get_API, Get_API_With_Token } from "../../../../services/ApiServies";
import { headers8 } from "../../tableLayoutData/tableData";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 10,
    padding: 10,
    boxShadow: "0px 0px 1px",
    backgroundColor: "#dee2e6",
  },
  boxMain: {
    backgroundColor: "#63244E",
    marginBottom: 10,
    padding: 10,
  },
  headerText: {
    color: "white",
    textAlign: "center",
    fontSize: 20,
    fontWeight: "600",
  },
  txt: {
    textAlign: "center",
    fontSize: 16,
  },
}));

const ReportsPage = () => {
  const classes = useStyles();
  const [tab2Data, setTab2Data] = useState([]);
  const global_data = useSelector((state) => state.commonReducer);
  const [startDate, setStartDate] = useState([]);

  const allData = useRef([]);

  useEffect(() => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        setTab2Data(response?.data?.objects);
        allData.current = response?.data?.objects;
        let dataSet = [...response?.data?.objects];
        let startEntry = dataSet?.sort(
          (a, b) => new Date(a.createdDate) - new Date(b.createdDate)
        );
        setStartDate(
          new Date(startEntry[0].createdDate).toISOString().split("T")[0]
        );
        console.log(
          new Date(startEntry[0].createdDate).toISOString().split("T")[0]
        );
      } else {
        console.log("Api call failed");
      }
    };

    Get_API_With_Token(
      Res.apiEndPoints.journalAnonymousReports,
      null,
      getModuleSuccess,
      global_data.token
    );
  }, []);

  const onDateChange = (fromDate, toDate) => {
    if (!!fromDate) {
      fromDate = new Date(fromDate);
    }
    if (!!toDate) {
      toDate = new Date(toDate);
    }
    let filtered;

    if (!!fromDate && !!toDate) {
      filtered = allData.current.filter(
        (report) =>
          new Date(report.createdDate) >= fromDate &&
          new Date(report.createdDate) <= toDate
      );
    } else if (!!fromDate) {
      filtered = allData.current.filter(
        (report) => new Date(report.createdDate) >= fromDate
      );
    } else if (!!toDate) {
      filtered = allData.current.filter(
        (report) => new Date(report.createdDate) <= toDate
      );
    } else {
      filtered = allData.current;
    }

    setTab2Data(filtered);
  };

  return (
    <div>
      <Box className={classes.boxMain}>
        <Typography className={classes.headerText}>Reports</Typography>
      </Box>
      <TableCommon
        headers={headers8({ onDateChange, startDate })}
        data={tab2Data}
        tableName="journalReport"
        serialNoDataField="entryId"
      />
    </div>
  );
};

export default ReportsPage;
