import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { footerHeight } from "./tableLayoutData/layoutData";

const useStyle = makeStyles((theme) => ({
  footerContainer: {
    height: footerHeight,
    backgroundColor: "#63244E",
  },
}));

const AdminFooter = () => {
  const styles = useStyle();
  return <Box className={styles.footerContainer}></Box>;
};

export default AdminFooter;
