import { useEffect, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import { Res } from "../../resources";
import { drawerWidth, headerHeight } from "./tableLayoutData/layoutData";
import { tabList } from "./tableLayoutData/tabList";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { DELETE_DATA } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../components/Modal";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) + 1,
    },
  },
  toolbar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  handlerIcon: {
    position: "absolute",
    top: 0,
    right: 5,
    color: theme.palette.primary.main,
  },
  logoBox: {
    height: headerHeight + 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },

  logoImg: {
    height: "50px",
    width: "50px",
  },
  logoImgSmall: {
    height: "25px",
    width: "26px",
  },
  menuItem: {
    height: 60,
    color: "#63244E",
    // marginBottom:10
  },
  selectedTab: {
    height: 60,
    // marginBottom:10,
    backgroundColor: "#63244E",
    color: "#fff",

    "& .MuiSvgIcon-root": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#63244E",
      color: "#fff",
      "& .MuiSvgIcon-root": {
        color: "#fff",
      },
    },
  },
  listItemtext: {
    "&  .MuiTypography-body1": {
      fontSize: "12px",
      fontWeight: "bold",
    },
    "& .MuiSvgIcon-root": {
      color: "#fff",
      position: "absolute",
      right: 0,
      top: 20,
    },
  },
}));

const AdminSidebar = ({
  tab,
  handleDrawerOpen,
  handleDrawerClose,
  changeTab,
  open,
}) => {
  const classes = useStyles();
  const global_data = useSelector((state) => state.commonReducer);
  const location = useLocation();
  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);

  const handleClickOpen = () => {
    setLogoutModalOpen(true);
  };

  const handleClose = () => {
    setLogoutModalOpen(false);
  };
  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
      }

      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);

          // Handle back event
        }
      }
    });
  }, [locationKeys]);
  const dispatch = useDispatch();
  // var indexCount = tabList.findIndex(p => p.route == history.location.pathname);
  return (
    <>
      <AlertDialog
        open={logoutModalOpen}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        title="Log out"
        description="Are you sure you want to log out ?"
        onYes={() => {
          dispatch({ type: DELETE_DATA });
          localStorage.clear();
          history?.push("/login");
        }}
      />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton
            className={classes.handlerIcon}
            onClick={open ? handleDrawerClose : handleDrawerOpen}
          >
            {open ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <div className={classes.logoBox}>
            <RouterLink to="/">
              <img
                className={open ? classes.logoImg : classes.logoImgSmall}
                src={Res.images.logoImage}
                alt={Res.images.logoImage}
              />
            </RouterLink>
          </div>
        </div>
        <List style={{ padding: 0 }}>
          {tabList.map((Item, index) => (
            <RouterLink
              key={index}
              style={{ textDecoration: "none" }}
              to={Item?.route}
            >
              <ListItem
                className={
                  Item.route === history.location.pathname
                    ? classes.selectedTab
                    : classes.menuItem
                }
                button
                key={Item.text}
                onClick={() => changeTab(index)}
              >
                <ListItemIcon style={{ marginTop: 0, marginLeft: 5 }}>
                  <Tooltip title={Item.tooltipText} placement="right">
                    <Item.icon fontSize="small" color="secondary" />
                  </Tooltip>
                </ListItemIcon>
                {open ? (
                  <ListItemText
                    primary={Item.text}
                    className={classes.listItemtext}
                  />
                ) : null}
              </ListItem>
            </RouterLink>
          ))}

          {!global_data.tokenAvailable && (
            <ListItem
              // className={index === tab ? classes.selectedTab : classes.menuItem}
              className={classes.menuItem}
              button
              onClick={() => {
                handleClickOpen();
              }}
            >
              <ListItemIcon style={{ marginTop: 0, marginLeft: 5 }}>
                <Tooltip title="Log Out" placement="right">
                  <PowerSettingsNewIcon fontSize="small" color="secondary" />
                </Tooltip>
              </ListItemIcon>
              <ListItemText
                primary={"Logout"}
                className={classes.listItemtext}
              />
            </ListItem>
          )}
        </List>
      </Drawer>
    </>
  );
};

export default AdminSidebar;

// import { useEffect, useState } from "react";
// import Drawer from "@material-ui/core/Drawer";
// import IconButton from "@material-ui/core/IconButton";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import { makeStyles } from "@material-ui/core/styles";
// import CloseIcon from "@material-ui/icons/Close";
// import MenuIcon from "@material-ui/icons/Menu";
// import clsx from "clsx";
// import React from "react";
// import { Link as RouterLink } from "react-router-dom";
// import { useLocation, useHistory } from "react-router-dom";
// import { Res } from "../../resources";
// import { drawerWidth, headerHeight } from "./tableLayoutData/layoutData";
// import { tabList } from "./tableLayoutData/tabList";
// import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
// import { DELETE_DATA } from "../../store/actions";
// import { useDispatch, useSelector } from "react-redux";
// import AlertDialog from "../../components/Modal";
// import { Tooltip } from "@material-ui/core";
// import SignOutMenu from "../../components/SignOutMenu";

// const useStyles = makeStyles((theme) => ({
//   drawer: {
//     width: drawerWidth,
//     flexShrink: 0,
//     whiteSpace: "nowrap",
//   },
//   drawerOpen: {
//     width: drawerWidth,
//     transition: theme.transitions.create("width", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     overflowX: "hidden",
//   },
//   drawerClose: {
//     transition: theme.transitions.create("width", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     overflowX: "hidden",
//     width: theme.spacing(6) + 1,
//     [theme.breakpoints.up("sm")]: {
//       width: theme.spacing(8) + 1,
//     },
//   },
//   toolbar: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     position: "relative",
//     padding: theme.spacing(0, 1),
//     ...theme.mixins.toolbar,
//   },
//   handlerIcon: {
//     position: "absolute",
//     top: 0,
//     right: 5,
//     color: theme.palette.primary.main,
//   },
//   logoBox: {
//     height: headerHeight + 100,
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     marginTop: 10,
//   },

//   logoImg: {
//     height: "50px",
//     width: "50px",
//   },
//   logoImgSmall: {
//     height: "25px",
//     width: "26px",
//   },
//   menuItem: {
//     height: 60,
//     color: "#63244E",
//     // marginBottom:10
//   },
//   selectedTab: {
//     height: 60,
//     // marginBottom:10,
//     backgroundColor: "#63244E",
//     color: "#fff",

//     "& .MuiSvgIcon-root": {
//       color: "#fff",
//     },
//     "&:hover": {
//       backgroundColor: "#63244E",
//       color: "#fff",
//       "& .MuiSvgIcon-root": {
//         color: "#fff",
//       },
//     },
//   },
//   listItemtext: {
//     "&  .MuiTypography-body1": {
//       fontSize: "12px",
//       fontWeight: "bold",
//     },
//     "& .MuiSvgIcon-root": {
//       color: "#fff",
//       position: "absolute",
//       right: 0,
//       top: 20,
//     },
//   },
// }));

// const AdminSidebar = ({
//   tab,
//   handleDrawerOpen,
//   handleDrawerClose,
//   changeTab,
//   open,
// }) => {
//   console.log("AdminSidebar", tab);
//   const classes = useStyles();
//   const global_data = useSelector((state) => state.commonReducer);
//   const location = useLocation();
//   const [locationKeys, setLocationKeys] = useState([]);
//   const history = useHistory();
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [isLogOutOpen, setLogOutOpen] = React.useState(true);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//     setLogOutOpen(true);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//     setLogOutOpen(false);
//   };
//   useEffect(() => {
//     return history.listen((location) => {
//       if (history.action === "PUSH") {
//         setLocationKeys([location.key]);
//       }

//       if (history.action === "POP") {
//         if (locationKeys[1] === location.key) {
//           setLocationKeys(([_, ...keys]) => keys);

//           // Handle forward event
//         } else {
//           setLocationKeys((keys) => [location.key, ...keys]);

//           // Handle back event
//         }
//       }
//     });
//   }, [locationKeys]);
//   const dispatch = useDispatch();
//   // var indexCount = tabList.findIndex(p => p.route == history.location.pathname);
//   return (
//     <>
//       <AlertDialog
//         open={logoutModalOpen}
//         handleClickOpen={handleClickOpen}
//         handleClose={handleClose}
//         title="Log out"
//         description="Are you sure you want to log out ?"
//         onYes={() => {
//           console.log("LOGOUT!");
//           dispatch({ type: DELETE_DATA });
//           localStorage.clear();
//           history?.push("/login");
//         }}
//       />
//       <Drawer
//         variant="permanent"
//         className={clsx(classes.drawer, {
//           [classes.drawerOpen]: open,
//           [classes.drawerClose]: !open,
//         })}
//         classes={{
//           paper: clsx({
//             [classes.drawerOpen]: open,
//             [classes.drawerClose]: !open,
//           }),
//         }}
//       >
//         <div className={classes.toolbar}>
//           <IconButton
//             className={classes.handlerIcon}
//             onClick={open ? handleDrawerClose : handleDrawerOpen}
//           >
//             {open ? <CloseIcon /> : <MenuIcon />}
//           </IconButton>
//           <div className={classes.logoBox}>
//             <RouterLink to="/">
//               <img
//                 className={open ? classes.logoImg : classes.logoImgSmall}
//                 src={Res.images.logoImage}
//                 alt={Res.images.logoImage}
//               />
//             </RouterLink>
//           </div>
//         </div>
//         <List style={{ padding: 0 }}>
//           {tabList.map((Item, index) => (
//             <RouterLink
//               key={index}
//               style={{ textDecoration: "none" }}
//               to={Item?.route}
//             >
//               <ListItem
//                 className={
//                   Item.route === history.location.pathname
//                     ? classes.selectedTab
//                     : classes.menuItem
//                 }
//                 button
//                 key={Item.text}
//                 onClick={() => changeTab(index)}
//               >
//                 <ListItemIcon style={{ marginTop: 0, marginLeft: 5 }}>
//                   <Tooltip title={Item.tooltipText} placement="right">
//                     <Item.icon fontSize="small" color="secondary" />
//                   </Tooltip>
//                 </ListItemIcon>
//                 {open ? (
//                   <ListItemText
//                     primary={Item.text}
//                     className={classes.listItemtext}
//                   />
//                 ) : null}
//               </ListItem>{" "}
//             </RouterLink>
//           ))}

//           {!global_data.tokenAvailable && (<>
//             <ListItem
//               // className={index === tab ? classes.selectedTab : classes.menuItem}
//               className={classes.menuItem}
//               button
//               onClick={() => {
//                 handleClickOpen();
//               }}
//             >
//               <ListItemIcon style={{ marginTop: 0, marginLeft: 5 }}>
//                 <Tooltip title="Log Out" placement="right">
//                   <PowerSettingsNewIcon fontSize="small" color="secondary" />
//                 </Tooltip>
//               </ListItemIcon>
//               {/* <ListItemText
//                 primary={"Logout"}
//                 className={classes.listItemtext}
//               /> */}
//             </ListItem>
//             <ListItem className={classes.menuItem} button onClick={handleClick}>
//               <ListItemIcon style={{ marginTop: 0, marginLeft: 5 }}>
//                 <Tooltip title="Log Out" placement="right">
//             {/*  <SignOutMenu /> */}
//                 </Tooltip>
//               </ListItemIcon>
//             </ListItem></>
//           )}
//         </List>
//       </Drawer>
//     </>
//   );
// };

// export default AdminSidebar;
