import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import TableCommon from "../../../../components/TableCommon";
import { headers3 } from "../../tableLayoutData/tableData";
import { Res } from "../../../../resources";
import { Get_API, Get_API_With_Token } from "../../../../services/ApiServies";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: 10,
    padding: 10,
    boxShadow: "0px 0px 1px",
    backgroundColor: "#dee2e6",
  },
  boxMain: {
    backgroundColor: "#63244E",
    marginBottom: 10,
    padding: 10,
  },
  headerText: {
    color: "white",
    textAlign: "center",
    fontSize: 20,
    fontWeight: "600",
  },
  txt: {
    textAlign: "center",
    fontSize: 16,
  },
}));

const JournalActivity = () => {
  const classes = useStyles();
  const [tab2Data, setTab2Data] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const global_data = useSelector((state) => state.commonReducer);

  useEffect(() => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        setTab2Data(response?.data?.objects);
      } else {
        console.log("Api called failed");
      }
    };

    Get_API_With_Token(
      Res.apiEndPoints.journalDashboard,
      null,
      getModuleSuccess,
      global_data.token
    );
  }, []);

  useEffect(() => {
    const getModuleSuccess1 = (response) => {
      if (response?.data?.success) {
        setHeaderData(response?.data?.object);
      } else {
        console.log("Api called failed");
      }
    };
    Get_API(
      Res.apiEndPoints.journalActivityReport,
      null,
      getModuleSuccess1,
      true
    );
  }, []);
  return (
    <div>
      <Box className={classes.boxMain}>
        <Typography className={classes.headerText}>Users</Typography>
      </Box>
      {/* <Box className={classes.container}>
        <Box>
          <Typography variant="caption">Total registered users</Typography>
          <Typography className={classes.txt}>
            {headerData?.numberOfSignUps}
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption">Yet to login </Typography>
          <Typography className={classes.txt}>
            {headerData?.noOfNeverloggedInUsers}
          </Typography>
        </Box>
      </Box> */}
      <TableCommon
        headers={headers3}
        data={tab2Data}
        serialNoDataField="id"
        tableName="journalReport"
      />
    </div>
  );
};

export default JournalActivity;
