import React from "react";

function MaskedUserId({ cell }) {
  const isEmail = cell.includes("@");

  const maskEmail = (email) => {
    if (email.length <= 4) return email;
    const start = email.substring(0, 2);
    const end = email.substring(email.length - 2);
    return `${start}${"x".repeat(email.length - 4)}${end}`;
  };

  return <div>{isEmail ? maskEmail(cell) : cell}</div>;
}

export default MaskedUserId;
