

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import TableCommon from "../../../../components/TableCommon";
import { headers5, } from "../../tableLayoutData/tableData";
import { Res } from "../../../../resources";
import { Get_API } from "../../../../services/ApiServies";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 10,
    padding: 10,
    boxShadow: "0px 0px 1px",
    backgroundColor: "#dee2e6"
  },
  mainBox: {
    backgroundColor: "#63244E",
    marginBottom: 10,
    padding: 10
  },
  headerText: {
    color: "white",
    textAlign: "center",
    fontSize: 20,
    fontWeight: '600'
  },
  cmntxt: {
    textAlign: 'center',
    fontSize: 16,
  },
}));

const WebinarPage = () => {
  const classes = useStyles();
  const [tab2Data, setTab2Data] = useState([]);
  const [dataCount, setDataCount] = useState([]);
  useEffect(() => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        setTab2Data(response?.data?.objects);
      } else {
        console.log("Api called failed");
      }
    }
    Get_API(
      Res.apiEndPoints.webinarsDashboard,
      null,
      getModuleSuccess,
      true
    );
    const getCountSuccess = (response) => {
      if (response?.data) { setDataCount(response?.data); } else { console.log("Api called failed"); }
    }
    Get_API(Res.apiEndPoints.contentCount, null, getCountSuccess, true);
  }, []);
  return (
    <div >
      <Box className={classes.mainBox}>
        <Typography className={classes.headerText}>Webinars</Typography>
      </Box>
      <Box className={classes.container}>
        <Box>
          <Typography variant="caption">Total webinars</Typography>
          <Typography className={classes.cmntxt}>{dataCount.totalWebinars}</Typography>
        </Box>
        <Box>
          <Typography variant="caption">Upcoming Webinars</Typography>
          <Typography className={classes.cmntxt}>{dataCount.upcomingWebinars}</Typography>
        </Box>
        <Box>
          <Typography variant="caption">Completed Webinars</Typography>
          <Typography className={classes.cmntxt}>{dataCount.completedWebinars}</Typography>
        </Box>

      </Box>
      <TableCommon headers={headers5} data={tab2Data} tableName="WebinarReport" />
    </div>
  );
};

export default WebinarPage;
