import { Box, Container, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import { Editor } from "@tinymce/tinymce-react";
import { useHistory, useParams } from "react-router";
import { Get_API, Post_API } from "../../services/ApiServies";
import { Res } from "../../resources";
import AdminFooter from "./AdminFooter";
import AdminSidebar from "./AdminSidebar";
import clsx from "clsx";
import { drawerWidth, headerHeight } from "./tableLayoutData/layoutData";

const makeStyle = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  appBar: {
    zIndex: theme.zIndex.drawer,
    height: headerHeight,
    paddingTop: theme.spacing(2.5),
    position: "static",
    paddingLeft: theme.spacing(8),
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  appBarElevation: {
    elevation: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingLeft: drawerWidth + 70,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: -50,
  },
}));

export const ContentEditor = () => {
  const styles = makeStyle();
  const { contentId } = useParams();
  const [dataId, setDataId] = useState("");
  const [dataType, setDataType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);

  const isSignUpInfo =
    contentId === "signupInfo" || contentId === "signupInfo_teacher";

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const changeTab = (index) => {
    setTab(index);
  };

  useEffect(() => {
    let suffix;
    if (
      contentId === "support" ||
      contentId === "terms_conditions" ||
      contentId === "privacy_policies"
    ) {
      suffix = "support";
    } else if (
      contentId === "support_teacher" ||
      contentId === "terms_conditions_teacher" ||
      contentId === "privacy_policies_teacher"
    ) {
      suffix = "support_teacher";
    } else if (contentId === "help" || contentId === "signupInfo") {
      suffix = "help";
    } else if (
      contentId === "help_teacher" ||
      contentId === "signupInfo_teacher"
    ) {
      suffix = "help_teacher";
    }
    Get_API(Res.apiEndPoints.content + "/" + suffix, null, getDataSuccess);
  }, []);

  const getDataSuccess = (res) => {
    if (contentId === "support" || contentId === "support_teacher") {
      setEmail(res.data.email);
      setPhone(res.data.phoneNumber);
      setDataId(res.data.id);
      setDataType(res.data.type);
    } else if (
      contentId === "terms_conditions" ||
      contentId === "privacy_policies" ||
      contentId === "terms_conditions_teacher" ||
      contentId === "privacy_policies_teacher"
    ) {
      let fieldName =
        contentId === "terms_conditions" ||
        contentId === "terms_conditions_teacher"
          ? "terms_conditions"
          : "privacy_policies";

      setTitle(res.data[fieldName]?.title);
      setDescription(res.data[fieldName]?.description);
      setDataId(res.data[fieldName]?.id);
      setDataType(res.data[fieldName].type);
    } else if (contentId === "help" || contentId === "help_teacher") {
      setTitle(res.data.title);
      setDescription(res.data.description);
      setDataId(res.data.id);
      setDataType(res.data.type);
    } else if (
      contentId === "signupInfo" ||
      contentId === "signupInfo_teacher"
    ) {
      setDataId(res.data.id);
      setDataType(res.data.type);
      setDescription(res.data.signupInfo);
    }
  };

  const saveHandler = () => {
    let data =
      contentId === "support" || contentId === "support_teacher"
        ? {
            id: dataId,
            email: email,
            phoneNumber: phone,
            type: dataType,
          }
        : contentId === "signupInfo" || contentId === "signupInfo_teacher"
        ? { id: dataId, signupInfo: description, type: dataType }
        : {
            id: dataId,
            title: title,
            description: description,
            type: dataType,
          };

    Post_API(
      Res.apiEndPoints.edit,
      data,
      (res) => {
        // if (res.data.success) {
        history.goBack();
        // }
      },
      true,
      true
    );
  };
  return (
    <div className={styles.pageContainer}>
      <div className={styles.root}>
        <AdminSidebar
          tab={tab}
          handleDrawerOpen={handleDrawerOpen}
          handleDrawerClose={handleDrawerClose}
          changeTab={changeTab}
          open={open}
        />

        <main
          className={clsx(styles.content, {
            [styles.contentShift]: open,
          })}
        >
          <Container style={{ maxWidth: 1130, marginTop: "10vh" }}>
            <Box className={styles.maincontainer}>
              <>
                <Box className={styles.inputBox}>
                  {!isSignUpInfo ? (
                    <CustomInput
                      inputLabe="Title"
                      inputType="text"
                      value={title}
                      onChange={(event) => {
                        setTitle(event.target.value);
                      }}
                    />
                  ) : null}
                </Box>
                <Box className={styles.inputBox}>
                  <Box pb={0.5} fontWeight="fontWeightMedium" fontSize={16}>
                    {isSignUpInfo ? "Text" : "Description"}
                  </Box>
                  <Editor
                    value={description}
                    init={{
                      selector: "textarea",
                      plugins: "link image code| lists",
                      toolbar:
                        "styleselect| numlist bullist| fontselect |underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat | lineheight| bold italic |fontsizeselect|  searchreplace undo redo | link image | bullist numlist outdent indent",
                      font_formats: `'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats'`,
                      fontsize_formats:
                        "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
                      min_height: 300,
                      lineheight_formats: "1 1.1 1.2 1.3 1.4 1.5 2",
                      placeholder: "Type here...",
                    }}
                    onEditorChange={(newValue, editor) => {
                      setDescription(newValue);
                    }}
                  />
                </Box>
              </>

              <Box display="flex" justifyContent="center">
                <Box mx={3} width="40%" mt={4}>
                  <CustomButton
                    buttonType="outlined"
                    buttonColor="primary"
                    buttonText="Cancel"
                    customStyle={{ borderRadius: 40 }}
                    clickHandler={() => {
                      history.goBack();
                    }}
                    dynamicText
                  />
                </Box>
                <Box mx={3} width="40%" mt={4}>
                  <CustomButton
                    buttonType="contained"
                    buttonColor="primary"
                    buttonText="Save"
                    customStyle={{ borderRadius: 40 }}
                    clickHandler={saveHandler}
                    // ajaxLoader={loaderState}
                    dynamicText
                  />
                </Box>
              </Box>
            </Box>
          </Container>
        </main>
        <AdminFooter />
      </div>
    </div>
  );
};
