import { Android, Dashboard } from "@material-ui/icons";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SubjectIcon from "@material-ui/icons/Subject";
import AssessmentIcon from "@material-ui/icons/Assessment";

export const tabList = [
  {
    text: (
      <>
        Recommendations <br /> Management <ChevronRightIcon />
      </>
    ),
    tooltipText: "Recommendations Management",
    id: 0,
    icon: Dashboard,
    route: "/recommendation",
  },
  {
    text: (
      <>
        Signup & Login Reports <ChevronRightIcon />
      </>
    ),
    tooltipText: "Signup & Login Reports",
    id: 1,
    icon: AccountCircleOutlinedIcon,
    route: "/signup_login_reports",
  },

  {
    text: (
      <>
        User Journal Activity Report <ChevronRightIcon />
      </>
    ),
    tooltipText: "User Journal Activity Report",
    id: 2,
    icon: SubjectIcon,
    route: "/user_journal_activity",
  },

  {
    text: (
      <>
        Dynamic App Content <ChevronRightIcon />
      </>
    ),
    tooltipText: "Dynamic App Content",
    id: 3,
    icon: Android,
    route: "/app_journal",
  },
  {
    text: (
      <>
        Reports <ChevronRightIcon />
      </>
    ),
    tooltipText: "Reports",
    id: 2,
    icon: AssessmentIcon,
    route: "/reports",
  },
];
