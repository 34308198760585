import React, { useEffect, useState } from "react";
import LoginSignInTotal from "./LoginSignUpTotal";
import { makeStyles } from "@material-ui/core/styles";
import { headers4, rows4 } from "../../tableLayoutData/tableData";
import TableCommon from "../../../../components/TableCommon";
import { Get_API } from "../../../../services/ApiServies";
import { Res } from "../../../../resources";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    display: "flex",
    flexDirection: "column",
    // alignItems: "flex-end",
  },
  table: {
    "& .MuiTableCell-root": { borderBottom: "none" },
    border: "2px solid #24355C",
  },
  rowLabel: {
    fontWeight: 600,
    color: "#24355C",
    textAlign: "center",
  },
  tableRow: {
    "& .MuiTableCell-head": {
      fontSize: "1em",
      color: "#24355C",
      fontWeight: 700,
      border: "2px solid #24355C",
    },
  },
  dataCell: {
    borderLeft: "2px solid #24355C",
  },
  bullet: {
    "&::before": {
      content: "",
      width: "5px",
      height: "5px",
      backgroundColor: theme.palette.primary.main,
    },
  },
  signupText: {
    color: "#24355C",
    fontWeight: "bold",
  },
  boxMain: {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#63244E",
    marginBottom: 5,
    textAlign: "center",
    fontSize: 18,
    padding: 4,
  },
}));

const LoginSignIn = ({ headers, rows }) => {
  const classes = useStyles();
  const [loginData, setLoginData] = useState([]);
  const [signupData, setSignupData] = useState([]);
  const [selectedTable, setSelectedTable] = useState("login");
  const [userFilter, setUserFilter] = useState("");
  const [manualFilter, setManualFilter] = useState("");
  const switchTable = (table) => {
    setSelectedTable(table);
  };

  useEffect(() => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        const responseObject = response?.data?.object
          .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
          .map((item, index) => {
            return { ...item, id: index + 1 };
          });
        setLoginData(responseObject);
      } else {
        console.log("Api called failed");
      }
    };
    const getSignInSuccess = (response) => {
      if (response?.data?.success) {
        const responseObject = response?.data?.object
          .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
          .map((item, index) => {
            return { ...item, id: index + 1 };
          });
        setSignupData(responseObject);
      } else {
        console.log("Api called failed");
      }
    };
    Get_API(Res.apiEndPoints.loginData, null, getModuleSuccess, true);
    Get_API(Res.apiEndPoints.signupData, null, getSignInSuccess, true);
  }, []);

  return (
    <div className={classes.tabContainer}>
      <LoginSignInTotal
        switchTable={switchTable}
        setUserFilter={setUserFilter}
        userFilter={userFilter}
        headers={headers}
        rows={rows}
        selectedTable={selectedTable}
      />

      <div style={{ height: "2em" }}></div>

      <div>
        {selectedTable == "login" ? (
          <>
            <Box className={classes.boxMain}>
              <Typography style={{ fontWeight: 600 }}>
                Login Report (
                {userFilter
                  ? loginData?.filter(
                      (item) => item.userType === userFilter?.toUpperCase()
                    ).length
                  : loginData?.length}
                )
              </Typography>
              <Box
                sx={{
                  fontSize: "1rem",
                  fontStyle: "italic",
                  textTransform: "capitalize",
                }}
              >
                {userFilter.toLowerCase()}
              </Box>
            </Box>
            <TableCommon
              headers={headers4({
                userFilter: userFilter,
                setUserFilter: setUserFilter,
                setManualFilter: setManualFilter,
              })}
              data={loginData}
              tableName="login"
              serialNoDataField="id"
            />
          </>
        ) : (
          <>
            <Box className={classes.boxMain}>
              <Typography style={{ fontWeight: 600 }}>
                Signup Report (
                {userFilter
                  ? signupData?.filter(
                      (item) => item.userType === userFilter?.toUpperCase()
                    ).length
                  : signupData?.length}
                )
              </Typography>
              <Box
                sx={{
                  fontSize: "1rem",
                  fontStyle: "italic",
                  textTransform: "capitalize",
                }}
              >
                {userFilter.toLowerCase()}
              </Box>
            </Box>
            <TableCommon
              headers={headers4({
                userFilter: userFilter,
                setUserFilter: setUserFilter,
                setManualFilter: setManualFilter,
              })}
              serialNoDataField="id"
              data={signupData}
              tableName="signup"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default LoginSignIn;
