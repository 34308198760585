import {
  Box,
  Container,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MobileData from "../../../../components/MobileData";

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(6),
    },
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },

  mainContainer: {
    padding: theme.spacing(8),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(6),
      textAlign: "center",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  bigGrid: {
    paddingRight: theme.spacing(4),
    // paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  headingText: {
    paddingBottom: theme.spacing(4),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  journalDetail: {
    lineHeight: 1.8,
    textAlign: "left",

    paddingBottom: theme.spacing(2.5),
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
    },
  },
  journalApp: {
    paddingTop: theme.spacing(10),
    display: "block",
    [theme.breakpoints.only("xs")]: {
      display: "none",
    },
  },
  journalAppMobile: {
    display: "none",
    [theme.breakpoints.only("xs")]: {
      display: "block",
      paddingTop: theme.spacing(2),
    },
  },
  bookingContainer: {
    marginLeft: "16px",

    [theme.breakpoints.down("xs")]: {
      paddingRight: "10%",
    },
  },
}));

function Journal(props) {
  const styles = makeStyle();

  const global_data = useSelector((state) => state.commonReducer);
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <div className={styles.pageContainer}>
      <Container style={{ minHeight: "100vh" }}>
        
        <Box height={10} />
        <MobileData />
        {/* <Grid>
          <PageBottomSection
            page="journal"
            item={journalBottonSection}
            buttonText="Book"
          />
        </Grid> */}
      </Container>
    </div>
  );
}

export default Journal;
