import React, { useState, useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import filterFactory from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Box, Grid, Link } from "@material-ui/core";
import "../../../../index.css";
import { useDispatch } from "react-redux";
import { GLOBAL_DATA } from "../../../../store/actions";
import { CustomizeToast } from "../../../../services/CommonLogic";
import SuggestionsTable from "./SuggestionsTable";
import { headers7 } from "../../tableLayoutData/tableData";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fBtn: {
    color: "blue",
    margin: 10,
    borderRadius: 10,
    fontSize: 15,
    "&:hover": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  downloadIcon: {
    color: "#63244E",
    height: 35,
    width: 35,
    borderRadius: 8,
    marginBottom: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  tableContainer: {
    "& .table>tbody>tr>:nth-child(4)": {
      textTransform: "capitalize",
    },

    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0.55em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#2E9F92",
      borderRadius: theme.spacing(0.2),
    },
    "& .table>tbody>tr>:nth-child(5)": {
      display: "none !important",
      width: "0px !important",
      position: "absolute !important",
    },
    "& .table>thead>tr>:nth-child(5)": {
      display: "none !important",
      width: "0px !important",
      position: "absolute !important",
    },
    "& .table>tbody>tr>:nth-child(4)": {
      width: "100% !important",
    },
    "& .table>thead>tr>:nth-child(4)": {
      width: "100% !important",
    },
    "& .table>tbody>tr>:nth-child(1)": {
      width: "5% !important",
    },
    "& .table>thead>tr>:nth-child(1)": {
      width: "5% !important",
    },
    "& .table>tbody>tr>:nth-child(3)": {
      width: "15% !important",
    },
    "& .table>thead>tr>:nth-child(3)": {
      width: "15% !important",
    },
  },

  customTable: {
    width: "90%",
    borderColor: "black",
    borderWidth: 0.3,
    borderStyle: "solid",
    borderCollapse: "collapse",
    fontSize: 12,
    marginBottom: 15,

    "& th": {
      backgroundColor: "grey",
      padding: 12,
      fontSize: 13,
      borderColor: "black",
      borderWidth: 0.3,
      borderStyle: "solid",
      borderCollapse: "collapse",
      textAlign: "center",
    },
    "& tr": {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "lightgrey",
        textDecoration: "none",
      },
    },
    "& td": {
      borderColor: "black",
      borderCollapse: "collapse",
      borderWidth: 0.3,
      borderStyle: "solid",
      padding: 10,
    },
  },
}));

export default function TableRecommendation(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = useState({});
  const [dataArr, setDataArr] = useState([]);

  const makeData = () => {
    const suggestions = props.data[selectedRow?.id - 1]?.suggestions ?? [];
    var parentSuggestions = suggestions?.filter((item) => {
      if (item.userType === "Parent") {
        return item;
      }
    });
    var teacherSuggestions = suggestions?.filter((item) => {
      if (item.userType === "Teacher") {
        return item;
      }
    });
    var totalRows =
      parentSuggestions.length > teacherSuggestions.length
        ? parentSuggestions.length
        : teacherSuggestions.length;
    const results = [];
    for (let i = 0; i < totalRows; i++) {
      results.push({
        parentSuggestions: parentSuggestions[i],
        teacherSuggestions: teacherSuggestions[i],
      });
    }
    setDataArr(results);
    return dataArr;
  };

  useEffect(() => {
    if (selectedRow?.id) makeData();
    dispatch({
      type: GLOBAL_DATA,
      payload: {
        selectedRow: selectedRow,
      },
    });
  }, [selectedRow]);

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <Grid item>
          <GetAppIcon
            className={classes.downloadIcon}
            fontSize="small"
            onClick={handleClick}
          />
        </Grid>
      </div>
    );
  };
  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total"
      style={{ marginLeft: 15 }}
    >
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: props?.data.length,
      },
    ],
  };
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRow(row);
      dispatch({
        type: GLOBAL_DATA,
        payload: {
          selectedRow: row,
        },
      });
    }
  };

  const selectRow = {
    mode: "radio",
    clickToSelect: true,
    clickToExpand: true,
    onSelect: handleOnSelect,
    hideSelectColumn: true,
    bgColor: "#E6E6FA",
  };

  const rowClasses = () => {
    return { borderColor: "black", borderWidth: "2", backgroundColor: "red" };
  };
  const expandRow = {
    showExpandColumn: true,
    onlyOneExpanding: true,
    renderer: (row) => {
      setSelectedRow(row);
      if (selectedRow?.id) {
        return (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Link
                className={classes.fBtn}
                onClick={() => {
                  props.func(true);
                }}
              >
                Edit Record
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <table className={classes.customTable}>
                <thead>
                  <tr style={{ display: "flex" }}>
                    {headers7?.map((head) => {
                      return <th style={{ flex: 1 }}>{head.text}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {dataArr.length > 0 ? (
                    dataArr?.map((body) => {
                      // console.log("body...........................");
                      // console.log(body);
                      return (
                        <tr style={{ display: "flex" }}>
                          <td style={{ flex: 1, wordBreak: "break-word" }}>
                            {body.parentSuggestions ? (
                              <>
                                Message :
                                <br />
                                {body.parentSuggestions.suggestion}
                                <br />
                                <br />
                                URL : <br />
                                <a
                                  style={{
                                    textDecoration: "none",
                                    color: "#000000",
                                  }}
                                  href={body.parentSuggestions.url}
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  {body.parentSuggestions.url}
                                </a>
                              </>
                            ) : null}
                          </td>
                          <td style={{ flex: 1, wordBreak: "break-word" }}>
                            {body.teacherSuggestions ? (
                              <>
                                Message :
                                <br />
                                {body.teacherSuggestions.suggestion}
                                <br />
                                <br />
                                URL : <br />
                                <a
                                  style={{
                                    textDecoration: "none",
                                    color: "#000000",
                                  }}
                                  href={body.teacherSuggestions.url}
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  {body.teacherSuggestions.url}
                                </a>
                              </>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <Box p={2} fontSize={17} textAlign="center">
                      No Recommendation Entry!
                    </Box>
                  )}
                </tbody>
              </table>
            </div>
          </>
        );
      } else {
        console.log("Please select one row");
      }
    },
  };

  return (
    <div>
      <ToolkitProvider
        bootstrap4
        keyField="id"
        data={props?.data}
        columns={props?.headers}
        exportCSV={{
          fileName: "Recommendation.csv",
          onlyExportFiltered: true,
          exportAll: true,
        }}
      >
        {(props) => (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginBottom: 10,
              }}
            >
              <MyExportCSV {...props.csvProps} />
            </div>
            <div
              className={classes.tableContainer}
              style={{
                overflow: "scroll",
                scrollBehavior: "smooth",
                maxHeight: 600,
                overflowX: "hidden",
                scrollbarColor: "skyblue",
                borderBottom: "1px solid black",
                borderLeft: "1px solid #F0F0F0",
                minWidth: "100%",
              }}
            >
              <BootstrapTable
                pagination={paginationFactory(options)}
                filter={filterFactory()}
                {...props.baseProps}
                hover
                striped
                rowClasses={rowClasses}
                noDataIndication="There is no data"
                selectRow={selectRow}
                // caption={<CaptionElement />}
                expandRow={expandRow}
              />
            </div>
          </React.Fragment>
        )}
      </ToolkitProvider>
    </div>
  );
}
