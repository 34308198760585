import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CreateOutlined } from "@material-ui/icons";
import { useHistory } from "react-router";
import { Get_API, Post_API } from "../services/ApiServies";
import { Res } from "../resources";
import CustomButton from "./CustomButton";
import { CustomizeToast } from "../services/CommonLogic";

const Types = { parent: "Parent", teacher: "TEACHER" };

const makeStyle = makeStyles((theme) => ({
  pageContainer: {
    paddingTop: theme.spacing(7),
    backgroundColor: theme.palette.paper,
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(6),
      // textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: { paddingTop: theme.spacing(11) },
  },
  iconClass: {
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  mainContainer: {
    borderTop: "2px solid #ccc",
    padding: theme.spacing(8),
    paddingTop: theme.spacing(2),
    "@media (max-width:800px)": {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2),
      textAlign: "center",
    },
  },
  inputContainer: {
    width: "100%",
    border: "0px",
    padding: 0,
    outline: "none",
    color: theme.palette.primary.main,
  },
  secondaryContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    border: "2px solid",
    color: theme.palette.primary.main,
  },
  innerbox: {
    width: "33vw",
  },
  headingBox: {
    padding: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    color: "white",
    fontWeight: 600,
    backgroundColor: theme.palette.primary.main,
    textAlign: "center",
  },
  boxStyles: {
    marginTop: theme.spacing(1),
  },
  contentBox: {
    padding: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0.7),
  },
  supportText: {
    fontSize: theme.spacing(3),
  },
  bigGrid: {
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  headingText: {
    paddingBottom: theme.spacing(4),
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("xs")]: {
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  supportHeading: {
    fontWeight: 600,
    fontSize: 25,
    width: "15vw",
  },
  fixedWidth: {
    width: "15vw",
  },
}));

const ModileData = () => {
  const history = useHistory();
  const styles = makeStyle();
  const [mobileSupport, setMobileSupport] = useState({});
  const [mobileHelp, setMobileHelp] = useState({});
  const [mobileSupportTeacher, setMobileSupportTeacher] = useState({});
  const [mobileHelpTeacher, setMobileHelpTeacher] = useState({});

  const [mobileSupportEmail, setMobileSupportEmail] = useState("");
  const [mobileSupportPhone, setMobileSupportPhone] = useState("");
  const [mobileSupportTeacherEmail, setMobileSupportTeacherEmail] =
    useState("");
  const [mobileSupportTeacherPhone, setMobileSupportTeacherPhone] =
    useState("");

  const [enableEdit, setEnableEdit] = useState("");
  const toggleEditMode = (type) => {
    // if (type === "") {
    setTimeout(() => {
      setEnableEdit(type);
    }, 500);
    // } else setEnableEdit(type);
  };

  useEffect(() => {
    Get_API(Res.apiEndPoints.content + "/support", null, (res) => {
      setMobileSupport(res.data);
      setMobileSupportEmail(res.data.email);
      setMobileSupportPhone(res.data.phoneNumber);
    });
    Get_API(Res.apiEndPoints.content + "/help", null, (res) => {
      setMobileHelp(res.data);
    });
    Get_API(Res.apiEndPoints.content + "/support_teacher", null, (res) => {
      setMobileSupportTeacher(res.data);
      setMobileSupportTeacherEmail(res.data.email);
      setMobileSupportTeacherPhone(res.data.phoneNumber);
    });
    Get_API(Res.apiEndPoints.content + "/help_teacher", null, (res) => {
      setMobileHelpTeacher(res.data);
    });
  }, []);

  const saveHandler = () => {
    if (enableEdit) {
      var dataId =
        enableEdit === Types.parent
          ? mobileSupport.id
          : mobileSupportTeacher.id;
      var email =
        enableEdit === Types.parent
          ? mobileSupportEmail
          : mobileSupportTeacherEmail;
      var phone =
        enableEdit === Types.parent
          ? mobileSupportPhone
          : mobileSupportTeacherPhone;
      var dataType =
        enableEdit === Types.parent
          ? mobileSupport.type
          : mobileSupportTeacher.type;

      Post_API(
        Res.apiEndPoints.edit,
        {
          id: dataId,
          email: email,
          phoneNumber: phone,
          type: dataType,
        },
        () => {
          CustomizeToast("Successfully saved");
        },
        true,
        true
      );
    }
  };
  return (
    <Grid container className={styles.mainContainer}>
      <Box className={styles.secondaryContainer}>
        <Box className={styles.innerbox}>
          <Typography className={styles.headingBox} variant="h5">
            My Family Journal Content
          </Typography>
          <Box className={styles.contentBox}>
            <Box className={styles.boxStyles}>
              <Box className={styles?.buttonContainer}>
                <Typography variant="h6" className={styles.supportHeading}>
                  Support
                </Typography>
                {enableEdit === Types.parent ? (
                  <Button size="small" variant="outlined" onClick={saveHandler}>
                    Save
                  </Button>
                ) : null}
              </Box>
              <Box className={styles?.buttonContainer}>
                Email:{" "}
                <input
                  onFocus={() => toggleEditMode(Types.parent)}
                  onBlur={() => toggleEditMode("")}
                  className={styles.inputContainer}
                  value={mobileSupportEmail}
                  onChange={(e) => setMobileSupportEmail(e.target.value)}
                  id="mobileSupportEmail"
                />
                <IconButton
                  onClick={() => {
                    document.getElementById("mobileSupportEmail")?.focus();
                  }}
                >
                  <CreateOutlined className={styles.iconClass} />
                </IconButton>
              </Box>
              <Box className={styles?.buttonContainer}>
                <Typography style={{ whiteSpace: "noWrap" }} variant="h6">
                  Phone Number:{" "}
                </Typography>
                <input
                  onFocus={() => toggleEditMode(Types.parent)}
                  onBlur={() => toggleEditMode("")}
                  className={styles.inputContainer}
                  value={mobileSupportPhone}
                  onChange={(e) => setMobileSupportPhone(e.target.value)}
                  id="mobileSupportPhone"
                />
                <IconButton
                  onClick={() => {
                    document.getElementById("mobileSupportPhone")?.focus();
                  }}
                >
                  <CreateOutlined className={styles.iconClass} />
                </IconButton>
              </Box>
            </Box>
            <Box className={styles.boxStyles}>
              <Box className={styles?.buttonContainer}>
                <Typography variant="h6" className={styles.fixedWidth}>
                  {mobileSupport?.terms_conditions?.title}
                </Typography>
                <IconButton
                  onClick={() => {
                    history.push(`/content/terms_conditions`);
                  }}
                >
                  <CreateOutlined className={styles.iconClass} />
                </IconButton>
              </Box>
            </Box>
            <Box className={styles.boxStyles}>
              <Box className={styles?.buttonContainer}>
                <Typography variant="h6" className={styles.fixedWidth}>
                  {mobileSupport?.privacy_policies?.title}
                </Typography>
                <IconButton
                  onClick={() => {
                    history.push(`/content/privacy_policies`);
                  }}
                >
                  <CreateOutlined className={styles.iconClass} />
                </IconButton>
              </Box>
            </Box>
            <Box className={styles.boxStyles}>
              <Box className={styles?.buttonContainer}>
                <Typography variant="h6" className={styles.fixedWidth}>
                  {mobileHelp?.title}
                </Typography>
                <IconButton
                  onClick={() => {
                    history.push(`/content/help`);
                  }}
                >
                  <CreateOutlined className={styles.iconClass} />
                </IconButton>
              </Box>
            </Box>
            <Box className={styles.boxStyles}>
              <Box className={styles?.buttonContainer}>
                <Typography variant="h6" className={styles.fixedWidth}>
                  Sign up Info
                </Typography>
                <IconButton
                  onClick={() => {
                    history.push(`/content/signupInfo`);
                  }}
                >
                  <CreateOutlined className={styles.iconClass} />
                </IconButton>
              </Box>
            </Box>
            <Box height="100px"></Box>
            <img
              src={Res.images.familyJournalCms}
              alt={Res.images.familyJournalCms}
              width="100%"
              className={styles.loginImg}
            />
          </Box>
        </Box>
        <Box className={styles.innerbox}>
          <Typography
            className={styles.headingBox}
            style={{ borderLeft: "2px solid white" }}
            variant="h5"
          >
            Teacher Journal Content
          </Typography>
          <Box
            className={styles.contentBox}
            style={{ borderLeft: "2px solid" }}
          >
            <Box className={styles.boxStyles}>
              <Box className={styles?.buttonContainer}>
                <Typography variant="h6" className={styles.supportHeading}>
                  Support
                </Typography>
                {/* <IconButton
                  onClick={() => {
                    history.push(`/content/support_teacher`);
                  }}
                >
                  <CreateOutlined className={styles.iconClass} />
                </IconButton> */}
                {enableEdit === Types.teacher ? (
                  <Button size="small" variant="outlined" onClick={saveHandler}>
                    Save
                  </Button>
                ) : null}
              </Box>
              <Box className={styles?.buttonContainer}>
                Email:{" "}
                <input
                  onFocus={() => toggleEditMode(Types.teacher)}
                  onBlur={() => toggleEditMode("")}
                  className={styles.inputContainer}
                  value={mobileSupportTeacherEmail}
                  onChange={(e) => setMobileSupportTeacherEmail(e.target.value)}
                  id="mobileSupportTeacherEmail"
                />
                <IconButton
                  onClick={() => {
                    // enableEdit === Types.teacher
                    //   ? saveHandler()
                    //   :
                    document
                      .getElementById("mobileSupportTeacherEmail")
                      ?.focus();
                  }}
                >
                  {/* {enableEdit === Types.teacher ? (
                    <DoneOutline className={styles.iconClass} />
                  ) : ( */}
                  <CreateOutlined className={styles.iconClass} />
                  {/* )} */}
                </IconButton>
              </Box>
              <Box className={styles?.buttonContainer}>
                <Typography style={{ whiteSpace: "noWrap" }} variant="h6">
                  Phone Number:{" "}
                </Typography>
                <input
                  onFocus={() => toggleEditMode(Types.teacher)}
                  onBlur={() => toggleEditMode("")}
                  className={styles.inputContainer}
                  value={mobileSupportTeacherPhone}
                  onChange={(e) => setMobileSupportTeacherPhone(e.target.value)}
                  id="mobileSupportTeacherPhone"
                />
                <IconButton
                  onClick={() => {
                    document
                      .getElementById("mobileSupportTeacherPhone")
                      ?.focus();
                  }}
                >
                  <CreateOutlined className={styles.iconClass} />
                </IconButton>
              </Box>
            </Box>
            <Box className={styles.boxStyles}>
              <Box className={styles?.buttonContainer}>
                <Typography variant="h6" className={styles.fixedWidth}>
                  {mobileSupportTeacher?.terms_conditions?.title}
                </Typography>
                <IconButton
                  onClick={() => {
                    history.push(`/content/terms_conditions_teacher`);
                  }}
                >
                  <CreateOutlined className={styles.iconClass} />
                </IconButton>
              </Box>
            </Box>
            <Box className={styles.boxStyles}>
              <Box className={styles?.buttonContainer}>
                <Typography variant="h6" className={styles.fixedWidth}>
                  {mobileSupportTeacher?.privacy_policies?.title}
                </Typography>
                <IconButton
                  onClick={() => {
                    history.push(`/content/privacy_policies_teacher`);
                  }}
                >
                  <CreateOutlined className={styles.iconClass} />
                </IconButton>
              </Box>
            </Box>
            <Box className={styles.boxStyles}>
              <Box className={styles?.buttonContainer}>
                <Typography variant="h6" className={styles.fixedWidth}>
                  {mobileHelpTeacher?.title}
                </Typography>
                <IconButton
                  onClick={() => {
                    history.push(`/content/help_teacher`);
                  }}
                >
                  <CreateOutlined className={styles.iconClass} />
                </IconButton>
              </Box>
            </Box>
            <Box className={styles.boxStyles}>
              <Box className={styles?.buttonContainer}>
                <Typography variant="h6" className={styles.fixedWidth}>
                  Sign up Info
                </Typography>
                <IconButton
                  onClick={() => {
                    history.push(`/content/signupInfo_teacher`);
                  }}
                >
                  <CreateOutlined className={styles.iconClass} />
                </IconButton>
              </Box>
            </Box>
            <Box height="100px"></Box>
            <img
              src={Res.images.teacherJournalCms}
              alt={Res.images.teacherJournalCms}
              className={styles.loginImg}
              width="100%"
            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ModileData;
