export const variables = {
    color: {
        primaryColor: "#63244E",
        primaryTextColor: "#2D3436",
        secondaryColor: "#63244E",
        whiteColor: "#ffffff",
        brightColor: '#FCDBAE',
        darkColor: "#3B3A3C",
        mainGrey: '#CEDBEA',
        darkGrey: "#6F6F6F",
        lightGrey: "rgba(80, 92, 109, 0.5)",
        brandText: "#9FBB33",
        brandColor: "#9EB934",
        mainGreen: '#2E9F92',
        darkGreen: "#206f66",
        lightGreen: "#57b2a7",
        red: '#ff3333',
        blue: '#75A8F4',
    },
    "toastTimer": 1000,
    "toastTimerDelay": 2000,

}