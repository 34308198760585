import { server } from "./server";

const endPoints = {
  signup: server.url + "/users/signup",
  verify: server.url + "/users/verify",
  userRegenerate: server.url + "/users/regenerate",
  login: server.url + "/users/login",
  forgotPassword: server.url + "/user/login/forgotPassword",
  resendOtp: server.url + "users/resendOTP",
  entry: server.url + "/journals/entry",
  entrySearch: server.url + "/journals/entry/search",
  categories: server.url + "/recommendations/categories",
  profile: server.url + "/profiles",
  profileOwner: server.url + "/profiles/owner",
  suscribeCourse: server.url + "/learnings/course/subscribe",
  allCourses: server.url + "/learnings/owner/",
  addModule: server.url + "/learnings/module/add",
  markCompleted: server.url + "/learnings/module/complete",
  getModule: server.url + "/learnings/modules/",
  usersWTB: server.url + "/users/wtb",
  profileChild: server.url + "/profiles/child",
  profilePicture: server.url + "/profiles/picture",
  recommendations: server.url + "/recommendations",
  journalActivityReport: server.url + "/users/admin/activity/dashboard",
  adminCourseDashboard: server.url + "/learnings/admin/courses/dashboard",
  webinarsDashboard: server.url + "/learnings/admin/webinars/dashboard",
  journalDashboard: server.url + "/journals/admin/dashboard",
  journalAnonymousReports: server.url + "/journals/entry/inforeport",
  recommendationsAll: server.url + "/recommendations/getAll",
  recommendationsById: server.url + "/recommendations/get/",
  categories: server.url + "/recommendations/categories",
  addKey: server.url + "/recommendations/recommendation/save",
  addMessage: server.url + "/recommendations/recommendation/suggestion/save",
  journalOwner: server.url + "/journals/owner",
  journalEntry: server.url + "/journals/entry",
  journalBuildReport: server.url + "/journals/buildReport/",
  journalSendReport: server.url + "/journals/sendReport/",
  pagesUrl: server.url + "/cms/contentitem/",
  authCourses: server.courseAuthUrl,
  allAuthCourses: server.allCoursesUrl,
  firebaseSubscribe: server.url + "/notifications-docs/notifications/subscribe",
  firebaseUnSubscribe:
    server.url + "/notifications-docs/notifications/unsubscribe",
  saveNotificationToken:
    server.url + "/notifications-docs/notifications/savetoken",
  coursesUrl: server.courseServerUrl,
  allNotification: server.url + "/notifications-docs/notifications/getall",
  watchNotification:
    server.url + "/notifications-docs/notifications/updatewatched",
  loginData: server.url + "/users/admin/signin/getall",
  signupData: server.url + "/users/admin/signup/getall",
  contentCount: server.url + "/contenthub/inventory",
  loginCount: server.url + "/users/admin/count/signin",
  signupCount: server.url + "/users/admin/count/signup",
  content: server.url + "/contenthub/page",
  edit: server.url + "/contenthub/content",
};

export const imgUrl = "https://teamteach.s3.ap-south-1.amazonaws.com/";

export const apiEndPoints = {
  ...server,
  ...endPoints,
};
