import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { useDispatch, useSelector } from "react-redux";
import { GLOBAL_DATA } from "../../../../store/actions";
import { Box, Container, Button, TextField } from "@material-ui/core";
import { Get_API, Post_API } from "../../../../services/ApiServies";
import { Res } from "../../../../resources";
import { useHistory } from "react-router-dom";
import {
  CustomizeToast,
  CustomizeToastError,
} from "../../../../services/CommonLogic";
import { isURL } from "../../../../resources/Validator";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid gray",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 10,
      border: "1px solid gray",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  firstRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  lastRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  firstContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: 280,
    padding: 40,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 25,
    marginRight: 25,
  },
  textStyle: {
    color: "#63244E",
    marginBottom: 15,
  },
  textStyle1: {
    color: "#63244E",
    marginBottom: 16,
    fontWeight: "bold",
    wordBreak: "break-word",
  },
  formControl: {
    minWidth: 80,
  },
  container: {
    overflowY: "scroll",
    scrollBehavior: "smooth",
    height: 800,
  },
  MenuItem: {
    fontSize: 11,
    color: "#63244E",
  },
  logoImg: {
    height: 20,
    width: 20,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  hederContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 6,
  },
  container: {
    overflowY: "scroll",
    scrollBehavior: "smooth",
    height: 800,
  },
  headerParent: {
    border: "2px solid black",
    marginTop: 10,
  },
  headerText: {
    color: "#63244E",
    fontSize: 16,
    fontWeight: "bolder",
    marginLeft: 20,
    marginTop: 20,
  },
  btnStyle: {
    backgroundColor: "#9D88B8",
    color: "#FFFFFF",
    marginRight: 10,
    borderRadius: 10,
    width: 100,
    padding: "4px 10px",
    fontSize: 10,
    marginTop: 20,
    "&:hover": {
      backgroundColor: "#9D88B8",
    },
  },
  btn2Style: {
    backgroundColor: "#63244E",
    color: "#FFFFFF",
    marginRight: 20,
    borderRadius: 10,
    width: 100,
    marginTop: 20,
    padding: "4px 10px",
    fontSize: 10,
    "&:hover": {
      backgroundColor: "#63244E",
    },
  },
  underLine: {
    backgroundColor: "#63244E",
    height: 3,
    marginBottom: 10,
  },
  txt: {
    color: "#63244E",
    marginTop: 8,
    marginBottom: 8,
  },
  txt1: {
    marginLeft: 90,
    color: "#63244E",
    marginTop: 8,
    marginBottom: 8,
  },

  divider: {
    color: "#63244E",
    marginTop: 8,
    fontSize: 15,
    fontWeight: "bold",
    marginRight: 5,
  },
  item: {
    borderRadius: 15,
    color: "#63244E",
    textAlign: "center",
    marginLeft: 4,

    marginRight: 8,
    minWidth: 80,
    display: "flex",
    flexDirection: "row",
  },
  newMsgTxt: {
    color: "#63244E",
  },
  itemcont: {
    wordBreak: "break-all",
    wordWrap: "break-word",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  boxsame: {
    display: "flex",
    flexDirection: "row",
  },
  add: {
    width: 22,
    height: 35,
  },
  cmnBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: "70%",
    marginBottom: 20,
  },
}));
export function isValidUrl(t) {
  return t.match(
    /^(http|https|ftp):\/\/(([A-Z0-9][A-Z0-9_-]*)(\.[A-Z0-9][A-Z0-9_-]*)+)(:(\d+))?\/?/i
  );
}
export default function AddMessage(props) {
  const history = useHistory();
  const global_data = useSelector((state) => state.commonReducer);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [msgObj] = React.useState([]);
  const [userType, setUserType] = React.useState("Parent");
  const [message, setMessage] = React.useState("");
  const [url, seturl] = React.useState("");
  const [wordCount, setWordCount] = React.useState(0);

  const handleChange3 = (b) => {
    let wordLength = b.target.value
      ?.split(new RegExp("\\s+"))
      ?.filter((word) => word != "").length;
    if (wordLength <= 36) {
      setWordCount(wordLength);
      setMessage(b.target.value);
    } else if (wordLength > 36) {
      b.target.value = message;
      CustomizeToastError("You can input only 36 words");
    } else {
      CustomizeToastError("You can input only 36 words");
    }
  };

  const handleChange4 = (b) => {
    seturl(b.target.value);
  };

  const handleChangeUserType = (event) => {
    setUserType(event.target.value);
  };

  const saveMsgClick = () => {
    if (
      isURL(url) &&
      message.trim() !== "" &&
      url.trim() !== "" &&
      userType.trim() !== ""
    ) {
      if (!isValidUrl(url)) {
        return CustomizeToastError("Please enter a valid url");
      }
      if (props?.edit?.suggestionIndex) {
        const addMsgKey = (response) => {
          if (response?.data?.success) {
            CustomizeToast(response?.data?.message);
            props.cancelBtnClick();
          } else {
            CustomizeToastError(response.data.message);
          }
        };

        Post_API(
          Res.apiEndPoints.addMessage,
          {
            message: message,
            recommendationId: props?.data?.recommendationId,
            suggestionIndex: props?.edit?.suggestionIndex,
            url: url,
            userType: userType,
          },
          addMsgKey,
          true
        );
      } else {
        const addMsgKey = (response) => {
          if (response?.data?.success) {
            CustomizeToast(response?.data?.message);
            props.cancelBtnClick();
            if (typeof props.loadDatatAfterNewMsg === "function") {
              props.loadDatatAfterNewMsg();
            }
          } else {
            console.log("Api called failed");
          }
        };
        if (userType !== "") {
          Post_API(
            Res.apiEndPoints.addMessage,
            {
              message: message,
              recommendationId: props?.data?.recommendationId,
              url: url,
              userType: userType,
            },
            addMsgKey,
            true
          );
        } else {
          CustomizeToastError("Please select User Type");
        }
      }

      msgObj.push({ message: message, url: url, userType: userType });
      dispatch({
        type: GLOBAL_DATA,
        payload: {
          Addmsg: msgObj,
        },
      });
      setMessage("");
      seturl("");

      const getModuleSuccess = (rslt) => {
        if (rslt?.data?.success) {
          dispatch({
            type: GLOBAL_DATA,
            payload: {
              recommendationsById: rslt?.data?.object,
            },
          });
        } else {
          console.log("Api called failed");
        }
      };

      Get_API(
        Res.apiEndPoints.recommendationsById +
          global_data?.selectedRow?.recommendationId,
        null,
        getModuleSuccess,
        true
      );
    } else {
      CustomizeToastError("Message and valid url required");
    }
  };
  return (
    <Container>
      <Box className={classes.headerParent}>
        <Box className={classes.hederContainer}>
          <Box className={classes.headerText}>Add New Message</Box>
          <Box>
            <Button
              size="small"
              className={classes.btnStyle}
              onClick={props.cancelBtnClick}
            >
              Cancel
            </Button>
            <Button
              size="small"
              className={classes.btn2Style}
              onClick={() => {
                saveMsgClick();
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
        <Container>
          <Box className={classes.underLine} />
        </Container>
        <Box style={{ marginLeft: "15%", marginRight: "15%" }}>
          <Box className={classes.cmnBox} style={{ paddingTop: "20px" }}>
            <Box className={classes.newMsgTxt}>Message* :</Box>
            {/* <BootstrapInput id="demo-customized-textbox" onChange={handleChange3} style={{width:'50%'}} value={message}  /> */}
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  m: 0,
                  width: "38ch",
                  border: "0px solid gray",
                },
              }}
              noValidate
              autoComplete="on"
            >
              <TextField
                id="demo-customized-textbox"
                variant="outlined"
                multiline
                onChange={handleChange3}
                rows={3}
                value={message}
                inputProps={{ style: { fontSize: 16 } }}
                required
              />
              <Box
                style={{
                  fontSize: 13,
                  paddingTop: "5px",
                  color: wordCount === 36 ? "#63244E" : "black",
                }}
              >
                {`${wordCount} of 36 words`}
              </Box>
            </Box>
          </Box>
          <Box className={classes.cmnBox}>
            <Box className={classes.newMsgTxt}>URL* :</Box>
            <BootstrapInput
              id="demo-customized-textbox"
              onChange={handleChange4}
              style={{ width: "50%" }}
              value={url}
              placeholder="e.g. https://www.example.com"
              required
            />
          </Box>
          <Box className={classes.cmnBox}>
            <Box className={classes.newMsgTxt}>Message type :</Box>
            <FormControl>
              <NativeSelect
                id="demo-customized-select-native"
                value={userType}
                onChange={handleChangeUserType}
                input={<BootstrapInput />}
                style={{
                  width: "100%",
                  color: "#63244E",
                  fontWeight: "bold",
                  fontSize: 15,
                }}
                // disabled={edit}
              >
                <option style={{ textAlign: "center" }} value={""}>
                  Select
                </option>
                <option style={{ textAlign: "center" }} value={"Parent"}>
                  Parent Messages
                </option>
                <option style={{ textAlign: "center" }} value={"Teacher"}>
                  Teacher Messages
                </option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
