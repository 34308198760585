import {
  DELETE_DATA,
  GLOBAL_DATA,
} from "./actions";

const intialState = {
  token: "",
  id: "",
  email: "",
  wtbToken: "",
  tokenAvailable: false,
  imageChanged: 0,
  profile: {
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    profileImage: "",
    timezone: null,
  },
  suscribe: {
  },
  sections: {

  },
  authSections: {
    description: "",
    durationHours: "",
    durationMinutes: "",
    hasTask: "",
    heading: "",
    image: "",
    price: "",
    sections: [

    ]
  },
  allCourses: {},
  addSections: {},
  podcastData: {},
  synoArr: [],
  selectedRow: {},
  recommendationsById: {},
  Addmsg: {},

  notifications: [],
  errors: [],
};

const commonReducer = (state = intialState, action) => {
  switch (action.type) {
    case GLOBAL_DATA:
      return Object.assign({}, state, action.payload);
    case "error":
      return {
        ...state,
        errors: [...state.errors, action.payload],
      };
    case DELETE_DATA:
      return intialState;

    default:
      return state;
  }
};
export default commonReducer;
