import { Button, CircularProgress, withStyles } from "@material-ui/core";
import React from "react";

const CustomButton = (props) => {
  const ButtonComponent = withStyles({
    root: {
      fontWeight: 400,
      borderRadius: 8,
      textTransform: "unset",
      width: props.width ? props.width : "100%",
      height: 38,
    },
  })(Button);

  return (
    <ButtonComponent
      variant={props.buttonType === "outlined" ? "outlined" : "contained"}
      style={props.customStyle}
      onClick={props.clickHandler ? props.clickHandler : null}
      color={props.buttonColor === "primary" ? "primary" : "secondary"}
      className={props.customClass}
      href={props.href ? props.href : null}
    >
      {props.ajaxLoader && (
        <CircularProgress style={{ color: "#fff", height: 21, width: 21 }} />
      )}

      {props.ajaxLoader && props.dynamicText ? "" : props.buttonText}
    </ButtonComponent>
  );
};

export default CustomButton;
