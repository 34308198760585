import React, { useState, useMemo, useRef } from "react";
import PropTypes from "prop-types";

function JournalEntryCell({ cell }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 200;
  const cellRef = useRef(null);

  const { truncatedText, isTruncated } = useMemo(() => {
    const truncated = cell.substring(0, maxLength);
    return { truncatedText: truncated, isTruncated: cell.length > maxLength };
  }, [cell]);

  const styles = {
    button: {
      display: "block",
      color: "#fff",
      borderRadius: 10,
      marginTop: 2,
      borderWidth: 0,
      backgroundColor: "#63244E",
      padding: "2px 8px",
      cursor: "pointer",
    },
  };

  const handleToggleExpand = () => {
    if (isExpanded) {
      cellRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    } 
    setIsExpanded((prev) => !prev);
  };

  return (
    <div ref={cellRef}>
      {isTruncated ? (
        <>
          {isExpanded ? cell : `${truncatedText}...`}
          <button style={styles.button} onClick={handleToggleExpand}>
            {isExpanded ? "Close" : "Read More"}
          </button>
        </>
      ) : (
        cell
      )}
    </div>
  );
}

export default JournalEntryCell;
