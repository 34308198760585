import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  row: {
    "&:hover": {
      cursor: "pointer",
      opacity: 0.9,
    },
  },
  rowLabel: {
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: ({ selected }) => (selected ? "#63244E" : "#9D88B8"),
    opacity: ({ selected }) => (selected ? 0.85 : 1),
    fontSize: ".80em",
    height: 20,
    padding: 10,
  },
}));

const LoginSignupRow = ({
  type,
  userType,
  activityData,
  onRowClick,
  selected,
}) => {
  const classes = useStyles({ selected });

  return (
    <TableRow className={classes.row} onClick={onRowClick}>
      <TableCell
        className={classes.rowLabel}
        component="th"
        scope="row"
        align="center"
        // onClick={() => switchTable("signin")}
      >
        {type}
      </TableCell>
      <TableCell
        className={classes.rowLabel}
        component="th"
        scope="row"
        align="center"
        // onClick={() => switchTable("signin")}
      >
        {userType}
      </TableCell>
      <TableCell
        className={classes.rowLabel}
        component="th"
        scope="row"
        align="center"
      >
        {activityData.today}
      </TableCell>
      <TableCell
        className={classes.rowLabel}
        component="th"
        scope="row"
        align="center"
      >
        {activityData.wtd}
      </TableCell>
      <TableCell
        className={classes.rowLabel}
        component="th"
        scope="row"
        align="center"
      >
        {activityData.mtd}
      </TableCell>
      <TableCell
        className={classes.rowLabel}
        component="th"
        scope="row"
        align="center"
      >
        {activityData.ytd}
      </TableCell>
      <TableCell
        className={classes.rowLabel}
        component="th"
        scope="row"
        align="center"
      >
        {activityData.total}
      </TableCell>
    </TableRow>
  );
};

export default LoginSignupRow;
