import { Box, Container, Grid, Icon, IconButton } from "@material-ui/core";
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import ParentMsg from "./ParentMsg";

import EditKeyword from "./EditKeyword";
import { useDispatch, useSelector } from "react-redux";
import { Get_API } from "../../../../services/ApiServies";
import { GLOBAL_DATA } from "../../../../store/actions";
import { Res } from "../../../../resources";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#FFFFFF",
    border: "1px solid black",
    fontSize: 15,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  firstRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "70%",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  lastRow: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "70%",
    wordWrap: "break-word",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  firstContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    // height: 280,
    padding: "4%",
    paddingTop: "1%",
    paddingBottom: "2%",
    marginTop: 15,
    marginBottom: 10,
    marginLeft: 25,
    marginRight: 25,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  textStyle: {
    color: "##63244E",
    marginBottom: 10,
    flex: 1,
  },
  textStyle1: {
    color: "##63244E",
    marginBottom: 10,
    fontWeight: "bold",
    flex: 2,
  },

  textStyleSyno: {
    color: "##63244E",
    textTransform: "Capitalize",
    marginBottom: 16,
    fontWeight: "bold",
    flex: 2,
  },
  formControl: {
    minWidth: 80,
  },
  container: {
    overflowY: "scroll",
    scrollBehavior: "smooth",
    maxHeight: 800,
  },
  MenuItem: {
    fontSize: 11,
    color: "##63244E",
  },
  logoImg: {
    height: 17,
    width: 17,
  },
  imageContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));

export default function AddKeyword(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const global_data = useSelector((state) => state.commonReducer);
  const [age, setAge] = React.useState("");
  const [edit, setEdit] = React.useState(true);
  const [recById, setRecById] = React.useState({});

  React.useEffect(() => {
    const getModuleSuccess = (response) => {
      if (response?.data?.success) {
        setRecById(response?.data?.object);
        dispatch({
          type: GLOBAL_DATA,
          payload: {
            recommendationsById: response?.data?.object,
          },
        });
      } else {
        console.log("Api called failed");
      }
    };
    if (global_data.selectedRow?.id) {
      Get_API(
        Res.apiEndPoints.recommendationsById +
          global_data?.selectedRow?.recommendationId,
        null,
        getModuleSuccess,
        true
      );
    }
  }, []);

  const handleChange = (event) => {
    setAge(event.target.value);
    setEdit(true);
  };
  const saveBtnClick = () => {
    setEdit(true);
  };
  return (
    <Box sx={{ border: "2px solid black" }} className={classes.container}>
      <div>
        <Box>
          {edit ? (
            <Box
              sx={{ border: "2px solid black" }}
              className={classes.firstContainer}
            >
              <Box className={classes.imageContainer}>
                <IconButton
                  style={{ padding: 4 }}
                  onClick={() => setEdit(false)}
                >
                  <Icon
                    color="primary"
                    style={{ fontSize: 19, fontWeight: 500 }}
                  >
                    create_icon
                  </Icon>
                </IconButton>
              </Box>
              <Box className={classes.lastRow}>
                <Box className={classes.textStyle}>Keyword :</Box>
                <Box className={classes.textStyle1}>
                  {recById?.keyword || "NA"}
                </Box>
              </Box>

              <div className={classes.firstRow}>
                <Box className={classes.textStyle}>Categories : </Box>
                <Box className={classes.textStyle1}>
                  <FormControl className={classes.margin}>
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={age}
                      onChange={handleChange}
                      input={<BootstrapInput />}
                      style={{
                        minWidth: 200,
                        color: "##63244E",
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                      disabled={true}
                    >
                      <option value={recById?.keyword}>
                        {recById?.category || "select"}
                      </option>
                    </NativeSelect>
                  </FormControl>
                </Box>
              </div>

              <div className={classes.lastRow}>
                <Box className={classes.textStyle}>Synonyms : </Box>
                <Box
                  className={classes.textStyleSyno}
                  dangerouslySetInnerHTML={{
                    __html:
                      (Array.isArray(recById.synonyms) && [
                        recById?.synonyms?.join(
                          "<span style='color:rgb(99, 36, 78); padding:5px;'> | </span>"
                        ),
                      ]) ||
                      "NA",
                  }}
                  component="span"
                />
              </div>
            </Box>
          ) : (
            <EditKeyword
              saveBtnClick={() => saveBtnClick()}
              data={recById}
              setData={setRecById}
            />
          )}
          <Box>
            <ParentMsg
              heading="Parent Messages :"
              data={recById}
              type="Parent"
            />
          </Box>
          {/* <Box>
                            <ParentMsg heading="Teacher Messages :" data={recById} type="Teacher" />
                        </Box> */}
          {/* <Box>
                            <ParentMsg heading="Carer Messages :" data={recById} type={"Carer"} />
                        </Box> */}
        </Box>
      </div>
    </Box>
  );
}
