import React, { useEffect, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";

const RangeFilter = ({ startDate, filterHandler }) => {
  const [startInputDate, setStartInputDate] = useState();
  const [endInputDate, setEndInputDate] = useState();

  const [minStartDate, setMinStartDate] = useState();
  const [maxStartDate, setMaxStartDate] = useState();
  const [minEndDate, setMinEndDate] = useState();
  const [maxEndDate, setMaxEndDate] = useState();

  useEffect(() => {
    resetFilters();
  }, [startDate]);

  const resetFilters = () => {
    let today = new Date().toISOString().split("T")[0];
    setStartInputDate(startDate);
    setEndInputDate(today);
    setMinStartDate(startDate);
    setMaxStartDate(today);
    setMinEndDate(startDate);
    setMaxEndDate(today);
  };
  const onStartDateChange = (e) => {
    setStartInputDate(e.target.value);
    setMinEndDate(e.target.value);
  };
  const onEndDateChange = (e) => {
    setEndInputDate(e.target.value);
    setMaxStartDate(e.target.value);
  };

  const clearFilter = () => {
    resetFilters();
    filterHandler(null, null);
  };

  const applyFilter = () => {
    filterHandler(startInputDate, endInputDate);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>Creation Date</div>
        <div style={{ display: "flex" }}>
          <ClearIcon
            style={{ cursor: "pointer" }}
            onClick={clearFilter}
            fontSize="small"
          />
          <DoneIcon
            style={{ cursor: "pointer" }}
            onClick={applyFilter}
            fontSize="small"
          />
        </div>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "50px" }}>From</div>
        <input
          type="date"
          className="filter"
          value={startInputDate}
          min={minStartDate}
          max={maxStartDate}
          onChange={onStartDateChange}
        />
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ width: "50px" }}>To</div>
        <input
          type="date"
          className="filter"
          min={minEndDate}
          max={maxEndDate}
          value={endInputDate}
          onChange={onEndDateChange}
        />
      </div>
    </>
  );
};

export default RangeFilter;
