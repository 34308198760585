import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Post_API } from "../../../../services/ApiServies";
import { Res } from "../../../../resources";
import LoginSignupRow from "./LoginSignupRow";

const useStyles = makeStyles((theme) => ({
  table: {
    borderColor: "black",
    borderWidth: 2,
    "& .MuiTableCell-root": { borderColor: "black", borderWidth: 2 },
  },
  row: {
    "&:hover": {
      cursor: "pointer",
      opacity: 0.85,
    },
  },
  rowLabel: {
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: "#9D88B8",
    fontSize: ".80em",
    height: 20,
    padding: 10,
  },
  rowLabelSecond: {
    fontWeight: "bold",
    color: "black",
    backgroundColor: "#fff",
    fontSize: ".80em",
    height: 20,
    padding: 10,
  },
  tableRow: {
    "& .MuiTableCell-head": {
      color: "white",
      backgroundColor: "#63244E",
      fontSize: ".80em",
      height: 20,
      padding: 8,
    },
  },
}));

const LoginSignInTotal = ({
  headers,
  selectedTable,
  switchTable,
  setUserFilter,
  userFilter,
}) => {
  const [loginActivityData, setLoginActivityData] = React.useState();
  const [signupActivityData, setSignupActivityData] = React.useState();
  React.useEffect(() => {
    const getLoginActivitySuccess = (response) => {
      if (response?.data?.success) {
        setLoginActivityData(response.data.object);

        // setSignupData(responseObject);
      } else {
        console.log("Api called failed");
      }
    };
    const getSignupActivitySuccess = (response) => {
      if (response?.data?.success) {
        setSignupActivityData(response.data.object);

        // setSignupData(responseObject);
      } else {
        console.log("Api called failed");
      }
    };
    Post_API(
      Res.apiEndPoints.loginCount,
      {
        fromDate: "2020-10-13",
        toDate: "2021-10-21",
      },
      getLoginActivitySuccess,
      true
    );
    Post_API(
      Res.apiEndPoints.signupCount,
      {
        fromDate: "2020-10-13",
        toDate: "2021-10-21",
      },
      getSignupActivitySuccess,
      true
    );
  }, []);

  const classes = useStyles();
  return (
    <>
      <Box sx={{ fontStyle: "italic" }}>
        *Click on the rows to see the detailed results in the table below
      </Box>

      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow className={classes.tableRow}>
            {headers?.map((header, i) => (
              <TableCell align="center">{header}</TableCell>
            ))}
          </TableRow>
          {loginActivityData?.parentResponse && (
            <LoginSignupRow
              type="Login"
              userType="Parent"
              onRowClick={() => {
                setUserFilter("PARENT");
                switchTable("login");
              }}
              selected={selectedTable === "login" && userFilter === "PARENT"}
              activityData={loginActivityData?.parentResponse}
            />
          )}
          {signupActivityData?.parentResponse && (
            <LoginSignupRow
              type="Signup"
              userType="Parent"
              onRowClick={() => {
                setUserFilter("PARENT");
                switchTable("Signup");
              }}
              selected={selectedTable !== "login" && userFilter === "PARENT"}
              activityData={signupActivityData?.parentResponse}
            />
          )}

          {loginActivityData?.teacherResponse && (
            <LoginSignupRow
              type="Login"
              userType="Teacher"
              onRowClick={() => {
                setUserFilter("TEACHER");
                switchTable("login");
              }}
              selected={selectedTable === "login" && userFilter === "TEACHER"}
              activityData={loginActivityData?.teacherResponse}
            />
          )}
          {signupActivityData?.teacherResponse && (
            <LoginSignupRow
              type="Signup"
              userType="Teacher"
              onRowClick={() => {
                setUserFilter("TEACHER");
                switchTable("Signup");
              }}
              selected={selectedTable !== "login" && userFilter === "TEACHER"}
              activityData={signupActivityData?.teacherResponse}
            />
          )}
          {loginActivityData?.adminResponse && (
            <LoginSignupRow
              type="Login"
              userType="Admin"
              onRowClick={() => {
                setUserFilter("ADMIN");
                switchTable("login");
              }}
              selected={selectedTable === "login" && userFilter === "ADMIN"}
              activityData={loginActivityData?.adminResponse}
            />
          )}

          {/* {signupActivityData?.adminResponse && (
            <LoginSignupRow
              type="Signup"
              userType="Admin"
              onRowClick={() => {
                console.log("heaaaa");
                setUserFilter("ADMIN");
                switchTable("Signup");
              }}
              activityData={signupActivityData?.adminResponse}
            />
          )} */}
        </TableHead>
      </Table>
    </>
  );
};

export default LoginSignInTotal;

// <TableRow className={classes.row}>
// <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable("login")}
// >
//   Login
// </TableCell>
// <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable("login")}
// >
//   {loginActivityData.today}
// </TableCell>
// <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable("login")}
// >
//   {loginActivityData.wtd}
// </TableCell>
// <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable("login")}
// >
//   {loginActivityData.mtd}
// </TableCell>
// <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable("login")}
// >
//   {loginActivityData.ytd}
// </TableCell>
// {/* <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable('login')}
// >
//   {loginActivityData.range}
// </TableCell> */}
// <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable("login")}
// >
//   {loginActivityData.total}
// </TableCell>
// </TableRow>
// {console.log("signiiiiiiiiiiiiup", signupActivityData)}

// <TableRow className={classes.row}>
// <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable("signin")}
// >
//   Signup
// </TableCell>
// <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable("signin")}
// >
//   {signupActivityData.today}
// </TableCell>
// <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable("signin")}
// >
//   {signupActivityData.wtd}
// </TableCell>
// <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable("signin")}
// >
//   {signupActivityData.mtd}
// </TableCell>
// <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable("signin")}
// >
//   {signupActivityData.ytd}
// </TableCell>
// {/* <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable('signin')}
// >
//   {signupActivityData.range}
// </TableCell> */}
// <TableCell
//   className={classes.rowLabel}
//   component="th"
//   scope="row"
//   align="center"
//   onClick={() => switchTable("signin")}
// >
//   {signupActivityData.total}
// </TableCell>
// </TableRow>
